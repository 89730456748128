.EnumFilter {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	gap: @padding-m;
	margin-bottom: @padding-m;
}

.EnumFilter-item,
.EnumFilter-toggle {
	display: flex;
	border-radius: @border-radius-s;
	user-select: none;
}

.EnumFilter-item {
	flex-basis: 160px;
	background: @color-filter-bg;
	border: 1px solid @color-filter-border;
}

.EnumFilter-toggle {
	flex-basis: content;
	background: transparent;
	border: 1px solid @color-filter-border;
}
.EnumFilter-toggle:hover {
	background: @color-white;
}

// this element is optional
.EnumFilter-tag {
	width: 10px;
	content: ' ';
}

.EnumFilter-control {
	flex-grow: 1;
	display: flex;
	align-items: baseline;
	gap: @padding-xs;
	padding: 5px 7px;
}

.EnumFilter-editor {
	accent-color: @color-primary;
	margin: 0;
}

.EnumFilter-label {
	font-size: @fs-s;
	flex-grow: 1;
}

.EnumFilter-count {
	font-size: @fs-xs;
	font-weight: @font-weight-bold;
}
