/* RESET */

blockquote, body, button, dd, div, dl, dt, figure, h1, h2, h3, h4, h5, h6, img, li, ol, p, pre, select, table, textarea, ul {
	font-size: inherit;
}

*:focus, *:focus-visible { outline: 0; }

// NOTE if your to be focussed element has already a box-shadow, you have to define a layout for focus separately (element can have only one box-shadow attribute at a time)
.visible-focus *:focus,
*:focus-visible {
	box-shadow: @focus-outline;
}

button {
	outline: 0 none; /* fuer Chrome */
	color: inherit;
	cursor: pointer;
}
