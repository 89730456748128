/*

<div class="arrowbuttons">
	<button class="arrowbutton white" type="button">
		<span class="fa fa-chevron-left"></span>
		<span class="sr-only">Nach links blättern</span>
	</button>
	<button class="arrowbutton" type="button">
		<span class="fa fa-chevron-right"></span>
		<span class="sr-only">Nach rechts blättern</span>
	</button>
</div>

*/

.arrowbuttons {
	display: flex;
	justify-content: center;
	gap: @padding-m;
	//margin: @padding-l 0 2 * @padding-l;
}

.arrowbutton {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	text-align: center;
	border: 2px solid transparent;
	background-color: @color-highlight;
	color: @color-white;
}

.arrowbutton:hover {
	border-color: @color-highlight;
	background-color: @color-hightlight-bg-hover;
}

.arrowbutton-inactive {
	opacity: .5;
}

.arrowbutton.white {
	background-color: @color-white;
	color: @color-brand-grey;
}

.arrowbutton.white:hover {
	border-color: @color-brand-grey;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.arrowbutton {
		filter: @button-shadow;
	}
}
// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.arrowbutton {
		filter: @button-shadow-mobile;
	}
}
