
.benefits-text {
	line-height: @line-height-s;
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.benefits {
		padding-top: @padding-l;
		padding-bottom: @padding-l;
	}
	.benefits-wrapper {
		display: flex;
		overflow-x: auto;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.benefits-headline {
		display: flex;
		flex-direction: column;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.benefits {
		padding-top: 2 * @padding-l;
		padding-bottom: 3 *@padding-l;
	}

	.benefits-text {
		width: 80%;
	}
	.benefits-wrapper {
		display: flex;
		flex-wrap: wrap;
	}
}
