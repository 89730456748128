.featuredroute {
	background-color: @color-brand-grey;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.featuredroute-headline-wrapper {
	display: flex;
	margin-bottom: @padding-m;
	gap: @padding-m;
}

.featuredroute-icon {
	width: 90px;
}

.featuredroute-headline.featuredroute-headline {
	display: flex;
	flex-direction: column;
	margin: 0;
}

.featuredroute-text {
	line-height: @line-height;
}

.featuredroute-text > *:not(:last-child) {
	margin-bottom: @padding-m;
}

.featuredroute-route-image {
	width: 100%;
}

.featuredroute-button {
	margin: @padding-m 0;
}

.featuredroute-detail-link {
	margin-top: @padding-m;
	text-align: right;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.featuredroute {
		padding: @padding-l 0;
		margin-top: @padding-l;
	}
	.featuredroute-inner {
		background: rgba(255, 255, 255, .85);
		width: @content-width;
		margin: 0 auto;
		padding: @padding-l;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.featuredroute-inner {
		border-radius: @border-radius-l;
		display: flex;
		gap: @padding-m;
	}
	.featuredroute-col {
		width: 50%;
	}

	.featuredroute-col2 {
		display: flex;
		flex-flow: column;
	}
	.featuredroute-route-image-container-desktop-and-tablet {
		flex-grow: 1;
		display: grid;
		align-items: center;
	}
	.featuredroute-image-mobile {
		display: none;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.featuredroute {
		// must overwrite inlinestyle here
		background-image: none !important;
		background-color: @color-white;
	}
	.featuredroute-inner {
		width: 100%;
		padding: @padding-m;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.featuredroute {
		padding: 2 * @padding-m @padding-m;
	}
	.featuredroute-inner {
		box-shadow: @box-shadow;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.featuredroute {
		margin-top: @padding-l;
		margin-bottom: @padding-l;
	}
	.featuredroute-inner {
		background: @color-white;
	}
	.featuredroute-route-image-container-desktop-and-tablet {
		display: none;
	}
}
