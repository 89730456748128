//-----------------------------------------------------------------------------
// These generic colors are to be used freely in components in your app
// they provide defaults which can be overriden ad lib

@color-text:					@color-grey-80;
@color-heading:					@color-grey-70;
@color-disabled:				@color-grey-70;
@color-light:					@color-grey-60;
@color-background:				@color-grey-10;

@color-primary:					#2D6DDE;
@color-primary-dark:			#1E4A93;

@color-label:					#5c6e89;
@color-input-border:			@color-grey-25;

@colorset-fg-ok:				#0a7a4c;
@colorset-bg-ok:				#a5e2c7;
@colorset-bg-ok-light:			#e6f7f0;

@colorset-fg-warn:				#ff9400;
@colorset-bg-warn:				#fdce9c;
@colorset-bg-warn-light:		#feeddb;

@colorset-fg-error:				#e76840;
@colorset-bg-error:				#f0cfcb;

@colorset-neutral:				@color-grey-70;

@colorset-box-bg-error:			#ffd5cf;
@colorset-box-border-error:		#cf332a;

@colorset-box-bg-warning:		#fdce9c;
@colorset-box-border-warning:	#e77700;

@colorset-box-bg-info:			#e4eef5;
@colorset-box-border-info:		#c1d2de;

@color-formerror:			#cf332a;
// not used in geko, provided for usage of form components on a dark background
@color-formerror-on-dark:	#ff9400;
@color-formerror-message:	#8f0e0e;

// ============================================================================
// Font settings – use or override ad lib

//-----------------------------------------------------------------------------
// Font-Family
// inspired by https://github.com/system-fonts/modern-font-stacks
// order: mac/ios, windows, linux, android, fallback

@font-normal:		'PT Sans', 'Avenir Next', 'Segoe UI', 'DejaVu Sans', source-sans-pro, sans-serif;
// we can use only typefaces which come with weight 400
@font-condensed:	'PT Sans Narrow', 'Avenir Next Condensed','Segoe UI', 'DejaVu Sans', sans-serif-condensed, sans-serif;
@font-mono:			'PT Sans Mono', Menlo, 'Cascadia Code', Consolas, 'Source Code Pro', 'DejaVu Sans Mono', monospace;

//-----------------------------------------------------------------------------
// Font-Weight

@font-weight-normal:	400;
@font-weight-medium:	500;
@font-weight-bold:		600;

//-----------------------------------------------------------------------------
// Font-Size

@fs-1:		36px;
@fs-2:		30px;
@fs-3:		24px;
@fs-4:		20px;

@fs-base:	16px;

@fs-s:		14px;
@fs-xs:		12px;
@fs-2xs:	10px;

//-----------------------------------------------------------------------------
// Line-Height
// line-height 1 should be the default and changed only for multiline paragraphs

@line-height:	1.45;
@line-height-m: 1.35;
@line-height-s: 1.25;
@line-height-xs: 1.1;

//-----------------------------------------------------------------------------
// Utilities: Border radius, shadows, borders etc.

@border-radius-s:	3px;
@border-radius:		5px;
@border-radius-l:	8px;

// Usage
// filter: drop-shadow(@box-drop-shadow);
@box-drop-shadow:		3px 3px 4px rgba(0,0,0,.5);	// used for form errors, wp action-formbox
@box-drop-shadow-light: 2px 2px 3px rgba(0,0,0,0.05);	// used for cards

// Usage
// box-shadow: @inner-shadow;
@inner-shadow:		inset 1px 1px 0px #f0f0f0;		// give al little depth to input elements
@box-shadow:		0 0 4px 0 rgba(0, 0, 0, 0.3);	// glow around

//-----------------------------------------------------------------------------
// Padding

@padding-xxl:	60px;
@padding-xl:	40px;
@padding-l:		30px;
@padding-m:		20px;
@padding-i:		15px;
@padding-s:		10px;
@padding-xs:	5px;
@padding-xxs:	3px;

//-----------------------------------------------------------------------------
// Sizes

// page.less
// TODO generic fix page body height depending on too many app specific variables
@app-header-height:		60px;
@app-main-padding:		@padding-xl;
// breadcrumbs + title + margins
@app-pageheader-height: @fs-s + @padding-s + @fs-1 + @padding-l;
@page-height:			calc(100vh - (@app-header-height + @app-main-padding + @app-pageheader-height + @app-main-padding));

// decor.less and message.less
@icon-size-small:	30px;
@icon-size-normal:	36px;

@breakpoint-desktop-min:	1200px;
@breakpoint-tablet-max:		@breakpoint-desktop-min - 1;
@breakpoint-tablet-min:		768px;
@breakpoint-mobile-max:		@breakpoint-tablet-min - 1;
@breakpoint-mobile-small:	480px;

// breakpoint for decor components to switch between responsive modes
@decor-breakpoint:	1800px;

// search.less, overrides @input-height of form.less
@search-height:		30px;

// modal.less
@modal-content-min-width:	680px;

// info.less
@info-label-width:	160px;
@info-column-gap:	15px;
