/* cookie-Hinweis */
.cookie-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000054;
	z-index: 1010;
}

.cookie-backdrop.off {
	display: none;
}

.cookie-message {
	background: white;
	border-top: 1px solid rgba(0,0,0,0.2);
	box-shadow: 3px 3px 6px rgba(0,0,0,0.5);
	font-size: 13px;
}

.cookie-intro {
	line-height: 1.5;
}

.cookie-textlink-light {
	color: #7d7b74;
	text-align: center;
	display: block;
	margin-top: @padding-i;
}

.cookie-checkboxrow {
	margin: @padding-l 0;
}

.cookie-button.cookie-button {
	padding: @padding-i;
}

// mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	// .cookie-backdrop {}

	.cookie-message {
		width: 100%;
		background: white;
		border-top: 1px solid rgba(0,0,0,0.2);
		box-shadow: 3px 3px 6px rgba(0,0,0,0.5);
		padding: @padding-i;
		font-size: 13px;
	}

	.cookie-checkbox {
		margin-top: @padding-m;
	}
	.cookie-buttonrow {
		margin: @padding-i 0 0 0;
	}

	.cookie-button {
		width: 100%;
		margin-bottom: @padding-i;
	}
}

// desktop and tablet
@media screen and (min-width: @breakpoint-tablet-min) {
	.cookie-message {
		width: 639px;
		margin-left: 39px;
		padding: 20px;
	}

	.cookie-checkbox {
		margin-right: @padding-m;
	}

	.cookie-checkboxrow {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
	}

	.cookie-buttonrow {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin: @padding-l 0 0;
	}

	// TODO layout make specificity hack obsolete, this is needed because the old primary-button has a fixed width
	.cookie-button.cookie-button {
		width: 48%;
	}
}
