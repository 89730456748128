// NOTE width and height have to be set by the code using the component
.Card {
	display: flex;
	flex-flow: column;
	border: 1px solid @color-card-border;
	background: @color-white;
	filter: drop-shadow(@box-drop-shadow-light);
	border-radius: @border-radius;
	font-size: @fs-s;
}

.Card:hover,
.Card:active,
.Card:focus {
	filter: drop-shadow(@box-drop-shadow);
	border: 1px solid @color-card-border-dark;
}

.Card-header {
	display: flex;
	align-items: center;
	// smaller bottom padding to optically compensate for the shift up by the truncate component
	padding: @padding-s @padding-s 7px @padding-s ;
	background: @color-card-headline-bg;
}

.Card-body {
	flex-grow: 1;
	padding: 15px @padding-s 0 @padding-s;
}

.Card-footer {
	padding: @padding-s;
}

// .Card--neutral {}

.Card--success {
	background: @colorset-bg-ok-light;
}
.Card--success .Card-header {
	background: @colorset-bg-ok;
}

.Card--warn {
	background: @colorset-bg-warn-light;
}
.Card--warn .Card-header {
	background: @colorset-bg-warn;
}
