.tags {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	padding: @padding-s;
	justify-content: flex-end;
	margin-bottom: @padding-l;
}

.tags-item:not(:last-child) {
	margin-right: @padding-xs;
}

.tags-item a {
	display: block;
	width: 100%;
	background: @color-brand-grey;
	color: @color-white;
	padding: @padding-s;
	border-radius: 1000px;
}

@media screen and (min-width: @breakpoint-tablet-min) {
	.tags {
		border-bottom: 1px dashed @color-brand-grey;
	}
}
