.intro {
	display: flex;
	gap: @padding-l;
}

.intro-image {
	background-color: @color-brand-grey;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: @border-radius;
	position: relative;
}

.intro-image-signature {
	font-family: @font-handdrawn;
	letter-spacing: @font-handdrawn-letterspacing;
	color: @color-white;
	filter: @text-shadow-white;
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	transform: rotate(-8deg);
}

.intro-texts {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.intro-headline {
	display: flex;
	flex-direction: column;
}

.intro-content {
	line-height: @line-height;
	display: flex;
	flex-direction: column;
	gap: @padding-m;
}

.intro-signatory {
	line-height: @line-height-s;
	text-align: right;
	padding: @padding-m 0;
}

.intro-signatory-title {
	color: @color-brand-grey;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.intro-image {
		// NOTE comment out if you rather want the image the same height as the text at its side
		height: @intro-image-height;
		background-position: center;
	}
	.intro-image-signature {
		font-size: 80px;
		bottom: 10%;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.intro-image {
		width: 33%;
	}
	.intro-texts {
		width: 67%;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.intro-image {
		width: 40%;
	}
	.intro-texts {
		width: 60%;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.intro {
		display: flex;
		flex-direction: column;
	}
	.intro-image {
		width: 100%;
		height: 500px;
		background-position: top center;
	}
	.intro-image-signature {
		font-size: 60px;
		bottom: 10%;
	}
	.intro-texts {
		width: 100%;
	}
}
