.ul {
	margin: @padding-s 0;
}

.li {
	line-height: @line-height;
}

.li-strong {
	font-weight: @font-weight-bold;
}

.blog .li:before {
	content:'◆';
	color: @color-brand-grey;
}
