.specialmoment {
	height: 600px;
	background-color: @color-brand-grey;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: @color-white;
	text-shadow: @box-drop-shadow;
}

.specialmoment-inner {
	display: flex;
	flex-flow: column;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.specialmoment-headline {
	//background: rgba(0,0,0,0.5);
	font-family: @font-handdrawn;
	letter-spacing: @font-handdrawn-letterspacing;
}

.specialmoment-text {
	//background: rgba(0,0,0,0.5);
	padding: 2 * @padding-l 0 2 * @padding-l;
	line-height: @line-height-m;
}

.specialmoment-align-c .specialmoment-name {
	text-align: right;
}

.specialmoment-align-r .specialmoment-content {
	text-align: right;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.specialmoment-align-r .specialmoment-text {
		padding-left: 40%;
	}

	.specialmoment-align-l .specialmoment-text {
		padding-right: 40%;
	}
	.specialmoment-align-c .specialmoment-text {
		padding-left: 12.5%;
		padding-right: 12.5%;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.specialmoment {
		height: 600px;
	}
	.specialmoment-headline {
		font-size: 100px;
	}
	.specialmoment-text {
		font-size: 36px;
	}
	.specialmoment-name {
		font-size: 20px;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.specialmoment {
		height: 500px;
	}
	.specialmoment-headline {
		font-size: 64px;
	}
	.specialmoment-text {
		font-size: 30px;
	}
	.specialmoment-name {
		font-size: 18px;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.specialmoment {
		height: 400px;
	}
	.specialmoment-headline {
		font-size: 48px;
	}
	.specialmoment-text {
		font-size: 24px;
	}
	.specialmoment-name {
		font-size: 16px;
	}
}
