/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import "_variables.less";

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: @fa-font-display;
  src: url('/fonts/fontawesome/fontawesome.woff2') format('woff2');
}

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300;
}
