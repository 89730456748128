.background {
	background-size: 800px 1200px;
 }

// On Mobile we display only the dark background with image, the others not
 .background-dark-white {
	background-image: url('/img/wp/topographic-dark-white.svg');
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.background {
		padding-top: 2 * @padding-l;
		padding-bottom: 3 * @padding-l;
	}

	.background.fade-in {
		position: relative;
	}

	.background.fade-in:before {
		content: "";
		display: block;
		position: absolute;
		top:0;
		width: 100%;
		height: 90px;
		background: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0.5) 80%, rgba(255,255,255,0));
	}

	.background.fade-out:after {
		content: "";
		display: block;
		position: absolute;
		bottom:0;
		width: 100%;
		height: 90px;
		background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.5) 80%, rgba(255,255,255,0));
	}

	//.background-transparent {}

	.background-white-grey {
		background-image: url('/img/wp/topographic-white-grey.svg');
	}

	.background-white-orange {
		background-image: url('/img/wp/topographic-white-orange.svg');
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.background {
		padding-top: @padding-m;
		padding-bottom: 2 * @padding-m;
	}
}
