.Empty {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - (@app-header-height + @app-main-padding + @fs-1 + @padding-l + @app-main-padding));
}

 .Empty-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
 }

.Empty-message {
	margin-top: @padding-l;
	text-align: center;
}

.Empty .fa {
	font-size: 120px;
	opacity: .3;
}

.Empty .fa-spin {
	-webkit-animation: fa-spin 4s infinite linear;
	animation: fa-spin 4s infinite linear;
}
