.hintitem-headline .fa-light {
	color: @color-highlight;
	text-align: center;
}

.hintitem-headline.headline {
	margin-top: 2 * @padding-m;
	margin-bottom: @padding-m;
	font-weight: @font-weight-normal;
}

.hintitem-text {
	line-height: @line-height;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.hintitem {
		page-break-inside: avoid;
	}
	.hintitem-headline .fa-light {
		width: 40px;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.hintitem-headline .fa-light {
		width: 24px;
	}
}
