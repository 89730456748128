@newsletter-input-width: 760px;
@newletter-input-height: 100px;

@newletter-button-width: 200px;

.newsletterbox {
	display: flex;
	align-items: center;
	flex-flow: column;
	position: relative;
}

.newsletterbox-headline {
	text-align: center;
	margin-bottom: 2 * @padding-m;
}

.newsletterbox-opener {
	position: relative;
	box-shadow: @box-shadow;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10000px;
	padding: @padding-s;
	background-color: @color-white;
}

.newsletterbox-opener .fa-light {
	color: @color-highlight;
	margin: 0 @padding-s;
}

.newsletterbox-fake-placeholder {
	color: @color-brand-grey;
	flex-grow: 1;
}

.newsletterbox-fake-signup {
	max-width: 40%;
	flex-shrink: 1;
	flex-grow: 0;
}

.newsletterbox-dialog {
	display: none;
}
.newsletterbox-dialog.open {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.newsletterbox-dialog-inner {
	height: auto;
	background-color: @color-white;
	padding: @padding-l;
	border: 1px solid @color-brand-grey;
	filter: @filter-drop-shadow;
	z-index: 1;
}

.newsletterbox-intro {
	display: flex;
	justify-content: space-between;
	font-size: @wp-fs-h5;
	margin-bottom: @padding-m;
}

.newsletterbox-submit {
	margin-top: @padding-m;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.newsletterbox-opener {
		width: 50%;
		font-size: @wp-fs-tablet-h4;
	}
	.newsletterbox-dialog-inner {
		max-width: 80%;
	}
	.newsletterbox {
		// - half of input-height - bottom-padding of background-shortcode - main bottom margin
		bottom: -40px - @main-bottom-margin-desktop;
		margin-top: -40px - @main-bottom-margin-desktop; // to adjust bottom shifting
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.newsletterbox {
		// - half of input-height - bottom-padding of background-shortcode - main bottom margin
		bottom: -40px - @main-bottom-margin-tablet-mobile;
		margin-top: -40px - @main-bottom-margin-tablet-mobile; // to adjust bottom shifting
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.newsletterbox-opener {
		width: 60%;
		font-size: @wp-fs-tablet-h4;
	}
	.newsletterbox-dialog-inner {
		max-width: 90%;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.newsletterbox-opener {
		width: 100%;
		font-size: @wp-fs-mobile-base;
	}
	.newsletterbox-dialog-inner {
		max-width: 100%;
	}
}
