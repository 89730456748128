.routeuplink {
	display: flex;
}

.routeuplink-image {
	width: 100%;
	border: 10px solid @color-white;
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.45);
	transform: rotate(-6deg);
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.routeuplink-image-wrapper {
		margin-right: 2 * @padding-l;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.routeuplink-image-wrapper {
		margin-right: 2 * @padding-m;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.routeuplink {
		margin: 2 * @padding-m 0 @padding-l;
		flex-direction: column;
	}
	.routeuplink-image-wrapper {
		margin: 0 @padding-m 2 * @padding-m;
	}
}

// Only big mobile
@media screen and (min-width: 450px) and (max-width: @breakpoint-mobile-max) {
	.routeuplink-image-wrapper {
		margin-left: auto;
		margin-right: auto;
		max-width: 95%;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.routeuplink {
		margin: 2 * @padding-l 0;
	}
	.routeuplink-image-wrapper {
		width: 50%;
	}
	.routeuplink-text {
		width: 50%;
		margin: auto 0;
	}
}
