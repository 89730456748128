// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.columns {
		display: flex;
		flex-flow: flex wrap;
		gap: @padding-m;
	}

	.columns-2 > * {
		width: 50%;
	}
}
