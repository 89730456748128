.Meta {
	display: flex;
	flex-flow: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: @padding-s;
	gap: @padding-s;
	font-size: @fs-xs;
	// fixed height to prevent hopping if meta doesn't contain a search field
	height: @search-height;
}
