.Headline {
	display: flex;
	align-items: baseline;
	margin: 0 0 @padding-s;
	gap: @padding-m;
	border-radius: @border-radius-s;
	padding: 0 14px;
	background: @color-headline-bg;
}

.Headline-title {
	font-weight: @font-weight-bold;
	flex-grow: 1;
	white-space: nowrap;
	padding: @padding-s 0;
}

.Headline-children {
	font-size: @fs-s;
	display: flex;
	gap: @padding-xs;
}
