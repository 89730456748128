@font-face {
    font-family: 'Asap';
    src: url('/fonts/asap/Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Asap';
    src: url('/fonts/asap/SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Asap';
    src: url('/fonts/asap/Italic.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Asap';
    src: url('/fonts/asap/SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
}
