// TODO form the default buttons should be width 100% and take their width from the surrounding container

.textlink {
	font-weight: @font-weight-bold;
	text-decoration: underline;
	cursor: pointer;
}

.textlink:hover, .textlink:active {
	color: @color-highlight-text-hover;
}

.iconlink {
	cursor: pointer;
	display: inline-flex;
	gap: 8px;
	align-items: baseline;
}

.iconlink:hover, .iconlink:active {
	color: @color-highlight-text-hover;
}

.iconlink-text {
	text-decoration: underline;
}

// NOTE to be ONLY used as mixin here
.button {
	width: 100%;
	height:	auto;
	line-height: 1;
	// Hack for round borders
	border-radius: 10000px;
	text-align: center;
	cursor: pointer;
	transition: background-color .3s, color .3s;
	display: block;
}

@media screen and (min-width: @breakpoint-tablet-min) {
	.primary-button,
	.ghost-button {
		font-size: @wp-fs-h5;
		padding: @padding-i @padding-m;
		//filter: @button-shadow;
	}
	.text-button {
		font-size: @wp-fs-h5;
	}
}

@media screen and (max-width: @breakpoint-mobile-max) {
	.primary-button,
	.ghost-button {
		font-size: @wp-fs-mobile-base;
		padding: @padding-i;
		//filter: @button-shadow-mobile;
	}
	.text-button {
		font-size: @wp-fs-mobile-base;
	}
}

.primary-button {
	.button;
	color: @color-white;
	font-weight: @font-weight-bold;
	text-shadow: @text-shadow;
	background-color: @color-highlight;

}

.primary-button:hover, .primary-button:active {
	background-color: @color-highlight-hover;
}

.ghost-button,
.text-button {
	.button;
	font-weight: @font-weight-bold;
	color: @ghost-button-color;
}

.ghost-button {
	border: 2px solid @ghost-button-color;
}

.ghost-button:hover, .ghost-button:active {
	background: @ghost-button-color;
	color: @color-white;
}

.text-button:hover, .text-button:active {
	text-decoration: underline;
}

.primary-button:focus-visible,
.ghost-button:focus-visible {
	box-shadow: @focus-outline;
}

.button-bar {
	margin-top: @padding-l;
}

.button-bar:not(:last-child) {
	margin-bottom: @padding-l;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.button-bar {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		gap: @padding-l;
	}
	.button-bar.button-bar--single {
		justify-content: flex-end;
	}
	.button-bar.button-bar--single.button-bar--center {
		justify-content: center;
	}
	.button-bar.button-bar--single > * {
		width: 50%;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.button-bar > * + * {
		margin-top: @padding-m;
	}
}
