@decor-large-width: 200px;
@decor-small-width: 164px;

/*
the height of the large primary, secondary and hoverinvert buttons is set implicitly
(using padding: 10 and font-size 16), this is only for the small icons and for the style circle
*/

@decor-large-height: @icon-size-normal;
@decor-small-height: @icon-size-small;

/*
.Decor {}

// styles
.Decor--style-text {}
.Decor--style-link {}
.Decor--style-primary {}
.Decor--style-secondary {}
.Decor--style-circle {}
.Decor--style-hoverinvert {}

// layout
.Decor--layout-always-label {}
.Decor--layout-always-icon {}
.Decor--layout-keep-label {}
.Decor--layout-keep-icon {}
.Decor--layout-switch {}

// layout variants
.Decor--content-both applies for
	Decor--layout-keep-label
	Decor--layout-keep-icon
	Decor--layout-switch

// sizes
.Decor--size-normal {}
.Decor--size-small {}

// children
.Decor-icon {}
.Decor-label {}
.Decor-hint {}
*/

.Decor-hint,
.Decor--layout-always-icon .Decor-label {
	.sr-only;
}

// NOTE we need this only for non-flexed buttons (the flexed have already a gap)
.Decor--style-text.Decor--content-both .Decor-icon,
.Decor--style-link.Decor--content-both .Decor-icon {
	margin-right: @padding-xs;
}

// NOTE this affects only non-inline variants (primary, secondary, circle and hoverinvert)
.Decor--layout-always-icon {
	width: @decor-large-height;
}

.Decor--style-link,
.Decor--style-hoverinvert {
	color: @color-primary-dark;
}

.Decor--style-link.Decor--layout-always-icon {
	padding-left: 5px;
	padding-right: 5px;
}

.Decor--style-primary,
.Decor--style-secondary,
.Decor--style-hoverinvert {
	display: flex;
	align-items: center;
	gap: @padding-s;
	border-radius: @border-radius;
}

.Decor--style-primary,
.Decor--style-secondary {
	justify-content: center;
	text-align: center;
}

.Decor--style-primary.Decor--content-both,
.Decor--style-primary.Decor--layout-always-label,
.Decor--style-secondary.Decor--content-both,
.Decor--style-secondary.Decor--layout-always-label {
	width: @decor-large-width;
}

.Decor--style-primary {
	background-color: @color-primary-dark;
	color: @color-white;
	padding: @padding-s;
}

.Decor--style-secondary {
	background-color: transparent;
	color: @color-primary-dark;
	border: 2px solid @color-primary-dark;
	padding: -2px + @padding-s @padding-s;
}

.Decor--style-circle {
	border-radius: calc( @decor-large-height / 2);
	color: @color-primary-dark;
	background-color: @color-white;
	border: 2px solid @color-primary-dark;
	height: @decor-large-height;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Decor--style-hoverinvert {
	justify-content: center;
}

.Decor--style-hoverinvert.Decor--content-both {
	justify-content: flex-start;
}

.Decor--style-hoverinvert.Decor--content-both,
.Decor--style-hoverinvert.Decor--layout-always-label {
	padding: @padding-s;
}

// TODO decor must add up to @decor-large-height
.Decor--style-hoverinvert.Decor--layout-always-icon {
	font-size: @fs-3;
	padding: @padding-xs @padding-s;
}

.Decor--style-hoverinvert .Decor-icon {
	width: @decor-large-height;
	text-align: center;
}

// -------------------------------------------------------------------------------
// Size variants

.Decor--style-text.Decor--size-small,
.Decor--style-link.Decor--size-small {
	font-size: @fs-xs;
}

.Decor--style-primary.Decor--size-small,
.Decor--style-secondary.Decor--size-small,
.Decor--style-circle.Decor--size-small,
.Decor--style-hoverinvert.Decor--size-small {
	height: @decor-small-height;
	gap: @padding-xs;
}

.Decor--style-primary.Decor--size-small {
	padding: @padding-xs;
}

.Decor--style-primary.Decor--size-small.Decor--content-both,
.Decor--style-secondary.Decor--size-small.Decor--content-both,
.Decor--style-primary.Decor--size-small.Decor--layout-always-label,
.Decor--style-secondary.Decor--size-small.Decor--layout-always-label {
	width: @decor-small-width;
}

// this affects only non-inline variants (primary, secondary, circle and hoverinvert)
.Decor--size-small.Decor--layout-always-icon {
	width: @decor-small-height;
}

.Decor--style-secondary.Decor--size-small {
	border: 1px solid @color-primary-dark;
	padding: -1px + @padding-xs @padding-xs;
}

.Decor--style-circle.Decor--size-small {
	border-radius: calc( @decor-small-height / 2);
}

.Decor--style-hoverinvert.Decor--size-small {
	font-size: @fs-base;
}

.Decor--style-hoverinvert.Decor--size-small .Decor-icon {
	width: @decor-small-height;
}

.Decor--style-hoverinvert.Decor--size-small.Decor--content-both,
.Decor--style-hoverinvert.Decor--size-small.Decor--layout-always-label {
	padding: @padding-xs;
}

// -------------------------------------------------------------------------------
// Responsiveness

@media screen and (max-width: @decor-breakpoint) {
	.Decor--content-both.Decor--layout-keep-label .Decor-icon {
		display: none;
	}

	.Decor--content-both.Decor--layout-keep-icon .Decor-label {
		.sr-only;
	}
	.Decor--content-both.Decor--layout-keep-icon {
		width: @decor-large-height;
	}
	.Decor--size-small.Decor--content-both.Decor--layout-keep-icon {
		width: @decor-small-height;
	}
}

// Wrapper components

// .Decorated {}
// .Link {}
// .ExternalLink {}
// .Button {}
// .Icon {}

// -------------------------------------------------------------------------------
// hover, active and focus styles

.interactive:where(a, button:enabled):hover {
	transition: all .1s ease;
}

.interactive:where(a, button:enabled):hover .Decor--style-primary {
	background-color: @color-primary;
}

.interactive:where(a, button:enabled):hover .Decor--style-hoverinvert,
.interactive:where(a, button:enabled):hover .Decor--style-hoverinvert .Decor-icon,
.interactive:where(a, button:enabled):hover .Decor--style-hoverinvert .Decor-label {
	color: @color-white;
	background-color: @color-primary-dark;
}

.interactive:where(a, button:enabled):hover .Decor--style-secondary {
	background-color: @color-white;
	border: 2px solid @color-primary;
	color: @color-primary;
}

.interactive:where(a, button:enabled):hover .Decor--style-link {
	color: @color-primary;
}

.interactive:where(a, button:enabled):hover .Decor--style-link.Decor--layout-always-label {
	text-decoration: underline;
}

.interactive:where(a, button:enabled):hover .Decor--style-secondary.Decor--size-small {
	border: 1px solid @color-primary;
}

.interactive:where(a, button:enabled):hover .Decor--style-circle {
	color: @color-white;
	background-color: @color-primary-dark;
	border: 2px solid transparent;
}

.interactive:where(a, button:enabled):active .Decor--style-primary,
.interactive:where(a, button:enabled):active .Decor--style-secondary,
.interactive:where(a, button:enabled):active .Decor--style-circle,
.interactive:where(a, button:enabled):active .Decor--style-hoverinvert {
	transform: translate(0.5px, 0.5px);
	transition: transform .1s;
}

// -------------------------------------------------------------------------------
// disabled

.interactive:disabled {
	cursor: not-allowed;
}

.interactive:disabled .Decor--style-primary {
	background-color: @color-disabled;
	color: @color-white;
}
.interactive:disabled .Decor--style-secondary {
	border: 2px solid @color-disabled;
	color: @color-disabled;
}

.interactive:disabled .Decor--style-text,
.interactive:disabled .Decor--style-link,
.interactive:disabled .Decor--style-hoverinvert {
	color: @color-disabled;
	text-decoration: none;
}

// -------------------------------------------------------------------------------
// DecoratedCombine
// combines Decor elements with bordered styles (primary and secondary)

.DecoratedCombine {
	display: flex;
	gap: 0;
}

.DecoratedCombine .Decorated:not(:first-child) .Decor {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin-left: -1px;
}

.DecoratedCombine .Decorated:not(:last-child) .Decor {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

// -------------------------------------------------------------------------------
// menu

.Menu {
	position: relative;
	white-space: nowrap;
}

.Menu > .Button .Decor {
	cursor: pointer;
	white-space: nowrap;
	flex-flow: row-reverse;
	justify-content: space-between;
}
.Menu-chevron {
	margin-left: @padding-xs;
}

.Menu-content {
	position: absolute;
	// just below the content wrapper which ends at the bottom of the header
	top: 100%;

	background: @color-white;
	border-bottom-right-radius: @border-radius;
	box-shadow: @box-shadow;
	width: auto;
	min-width: 100%;
	max-width: 200%;
	height: 0;
	transform: scaleY(0);
	transform-origin: bottom;
	// TODO decor we would like to have this when content grows too big,
	// but together with the transform: scaleY(0); this sometimes leads to content not being rendered
	// overflow: hidden;
	z-index: 2;
}

// at least in chromium 122.0.6261.57 the :hover stays on when testing as a touch device
.Menu--hoverable:hover	.Menu-content,
.Menu--hoverable		.Menu-content:hover,
.Menu--open			.Menu-content {
	height: auto;
	transform: scaleY(1);
	transform-origin: top;
	transition: transform .3s ease;
}

.MenuItem {
	display: block;
	color: @color-text;
}

.MenuItem:hover {
	background: @color-menu-hover-bg;
}

.MenuItem .Decorated {
	display: block;
	padding: @padding-s @padding-xs;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.MenuItem--custom {
	display: flex;
	justify-content: space-between;
}

.MenuItem--custom .Decorated:first-child {
	flex-grow: 1;
}
