.InfoList {
	display: flex;
	flex-flow: column;
	gap: @padding-s;
}

// .InfoList-style--wrap {}
// .InfoList-style--nowrap {}

.Info {
	line-height: @line-height-s;
}

.Info-label {
	font-weight: @font-weight-medium;
}

//-----------------------------------------------------------------------------
// wrap

.InfoList-style--wrap .Info-content {
	margin-left: @padding-m;
}

//-----------------------------------------------------------------------------
// nowrap

.InfoList-style--nowrap > .Info {
	display: flex;
	gap: @info-column-gap;
	align-items: baseline;
	font-family: @font-condensed;
}

.InfoList-style--nowrap .Info-label {
	text-align: right;
	width: @info-label-width;
	flex-shrink: 0;
}

.InfoList-style--nowrap .Info-content {
	flex-grow: 1;
	// prevent a truncate overflowing the container
	min-width: 0;
}
