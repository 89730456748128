// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.breadcrumbs {
		margin-bottom: 2 * @padding-l;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.breadcrumbs {
		margin-bottom: 3 * @padding-i;
	}
}

// Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.breadcrumbs {
		display: none;
	}
}

.breadcrumbs-item {
	display: inline;
}

.breadcrumbs-home,
.breadcrumbs-parent {
	color: @color-brand-grey;
}

.breadcrumbs-home:after,
.breadcrumbs-parent:after {
	content: " > ";
}

.breadcrumbs-current {
	font-weight: @font-weight-bold;
}

.breadcrumbs-link:hover {
	text-decoration: underline;
}
