.starter-item {
	border-radius: @border-radius-l;
	background: @color-brand-grey;
	color: @color-white;
	padding: @padding-s;
}

.starter-item-header {
	display: flex;
	align-items: center;
	margin-bottom: @padding-i;
}

.starter-item-icon {
	height: 100px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	flex-shrink: 0;
}

.starter-item-icon-1 { background-image: url('/img/wp/start-survey.svg'); }
.starter-item-icon-2 { background-image: url('/img/wp/start-planner.svg'); }
.starter-item-icon-3 { background-image: url('/img/wp/start-meeting.svg'); }

.starter-item-text {
	line-height: @line-height-m;
}

.starter-item-button {
	border: 2px solid @color-white;
	padding: @padding-s;
	text-align: center;
	width: 100%;
	display: block;
	border-radius: 1000px;
	font-weight: @font-weight-bold;
}

.starter-item-button:hover {
	background: @color-highlight;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.starter {
		margin-bottom: 2 * @padding-l;
	}
	.starter-item-icon {
		width: 35%;
	}
	.starter-item-button {
		font-size: @wp-fs-h4;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.starter {
		display: flex;
	}
	.starter-item {
		width: calc(33% - @padding-s);
	}
	.starter-item:not(:first-child) {
		margin-left: 3 * @padding-s;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.starter {
		margin-bottom: 2 * @padding-m;
	}
	.starter-item-icon {
		width: 40%;
	}
	.starter-item-button {
		font-size: @wp-fs-h5;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.starter {
		margin-bottom: 2 * @padding-i;
	}
	.starter-item:not(:last-child) {
		margin-bottom: @padding-l;
	}
	.starter-item-icon {
		width: 40%;
		height: 60px;
	}
	.starter-item-button {
		font-size: @wp-fs-h5;
	}
}
