.hero {
	width: 100%;
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: @wp-grey-80;
	margin-bottom: 2 * @padding-l;
}

.hero-text {
	color: @color-white;
	filter: @text-shadow-white;
	text-align: left;
	font-weight: @font-weight-bold;
	text-wrap: balance;
}

.hero-text-title {}

.hero-text-subtitle {
	color: @color-highlight;
	padding-top: @padding-s;
}

.hero-video {
	position: absolute;
	object-fit: cover;
	width: calc(100vw - var(--scrollbar-width));
}

@media (prefers-reduced-motion) {
	.hero-video {
		display: none;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.hero-text {
		font-size: @wp-fs-h0;
		margin-top: (@hero-desktop-height / 2);
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.hero .contentwidth {
		display: flex;
		align-items: center;
		height: 100%;
	}
	.hero-text {
		font-size: @wp-fs-tablet-h0;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.hero {
		align-items: flex-start;
	}
	.hero,
	.hero-video {
		height: @hero-desktop-height;
	}
	.hero-text {
		// Safeguard for the text not to run into the questionnaire
		width: 60%;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.hero {
		align-items: flex-end;
	}
	.hero,
	.hero-video {
		height: @hero-mobile-height;
	}
	.hero-text {
		font-size: 26.88px;
		margin: 0 0 @padding-xxl;
		// Avoid content with full line length
		width: 80%;
	}
}
