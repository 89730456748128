.header {
	position: relative;
	box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
}

.headerbar {
	color: @color-white;
	background: @color-brand-grey;
}

.headerbar-inner {
	display: flex;
	justify-content: space-between;
	padding: @padding-s;
}

.header-mobile-menu,
.header-mobile-menu-button {
	display: none;
}

.header-menu .menu,
.header-contact,
.header-contact-links,
.header-lang {
	display: flex;
	gap: @padding-m;
}

.header-contact-mail {
	.sr-only;
}

.header-lang-icon {
	width: 20px;
	height: 16px;
	background-repeat: no-repeat;
	background-size: cover;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.headerbar-inner {
		width: @content-width;
		margin: 0 auto;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.header-menu {
		display: flex;
		align-items: center;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.landingpage .header {
		margin-top: 50px;
	}
	.headerbar-inner {
		width: 100%;
	}

	.header-lang {
		align-items: center;
	}
	.header-lang-icon {
		width: 32px;
		height: 24px;
	}
	.headerbar .fa-light {
		font-size: 30px;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.headerbar {
		position: relative;
	}
	.header-menu { display: none }
	.header-mobile-menu { display: block; }

	.header-inner {
		display: flex;
		justify-content: space-between;
	}

	.header-mobile-menu-button { display: block; }

	.header-mobile-menu {
		width: 100%;
		position: absolute;
		transition: transform .3s ease;
		transform: scaleY(0);
		transform-origin: top;
		top: 100%;
		left: 0;
		color: @color-white;
		background: @color-brand-grey;
		border-top: 1px solid @color-white;
		z-index: 3;
	}
	.header-mobile-menu.open {
		transform: scaleY(1);
	}

	.header-mobile-menu .menu {
		display: initial;
	}
	.header-mobile-menu .menu-item {
		border-bottom: 1px solid @color-white;
	}

	.header-mobile-menu .menu-item > a {
		padding: @padding-i;
		width: 100%;
		display: block;
	}
	.header-contact-text {
		.sr-only;
	}
}
