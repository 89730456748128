// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.float.left,
	.float.right {
		max-width: 40%;
		margin-bottom: @padding-m;
	}

	.float.left {
		float: left;
		margin-right: @padding-m;
	}

	.float.right {
		float: right;
		margin-left: @padding-m;
	}
}
// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
}
