.hl-wrapper {
	display: flex;
}

.hl {
	font-weight: @font-weight-bold;
	line-height: @line-height-s;
}
.hl .fa-light {
	color: @color-highlight;
}

.hl-1 {
	margin-bottom: @padding-m;
}

.hl-2 {
	margin-top: @padding-l;
	margin-bottom: @padding-m;
}

.hl-3 {
	margin-top: @padding-m;
	margin-bottom: @padding-i;
}

.hl-4 {
	margin-bottom: @padding-s;
}

.hl-5 {
	margin-bottom: @padding-s;
}

.hl-6 {
	margin-bottom: @padding-s;
}

.hl-text1,
.hl-text2 {
	display: inline;
}

.hl-text2 {
	color: @color-brand-grey;
	font-weight: @font-weight-normal;
}

.hl-1.hl-with-image .hl-text2 {
	display: inline-block;
}

.hl-error {
	color: @color-error;
}

.hl-image {
	object-fit: contain;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.hl-1 {
		font-size: @wp-fs-h1;
	}

	.hl-2 {
		font-size: @wp-fs-h2;
	}

	.hl-3 {
		font-size: @wp-fs-h3;
	}

	.hl-4 {
		font-size: @wp-fs-h4;
	}

	.hl-5 {
		font-size: @wp-fs-h5;
	}

	.hl-6 {
		font-size: @wp-fs-h6;
	}
	.hl-image {
		max-width: 20%;
		margin-left: @padding-m;
	}
	.hl-1.hl-with-image .hl-text2 {
		font-size: @wp-fs-h3;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.hl-1 {
		font-size: @wp-fs-tablet-h1;
	}

	.hl-2 {
		font-size: @wp-fs-tablet-h2;
	}

	.hl-3 {
		font-size: @wp-fs-tablet-h3;
	}

	.hl-4 {
		font-size: @wp-fs-tablet-h4;
	}

	.hl-5 {
		font-size: @wp-fs-tablet-h5;
	}

	.hl-6 {
		font-size: @wp-fs-tablet-h6;
	}
	.hl-image {
		max-width: 40%;
		max-height: 200px;
		margin-left: @padding-m;
	}
	.hl-1.hl-with-image .hl-text2 {
		font-size: @wp-fs-tablet-h3;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.hl-wrapper {
		flex-direction: column-reverse;
	}
	.hl-1 {
		font-size: @wp-fs-mobile-h1;
	}

	.hl-2 {
		font-size: @wp-fs-mobile-h2;
	}

	.hl-3 {
		font-size: @wp-fs-mobile-h3;
	}

	.hl-4 {
		font-size: @wp-fs-mobile-h4;
	}

	.hl-5 {
		font-size: @wp-fs-mobile-h5;
	}

	.hl-6 {
		font-size: @wp-fs-mobile-h6;
	}
	.hl-image {
		width: 60%;
		max-height: 150px;
		margin: 0 auto @padding-m;
	}
	.hl-1.hl-with-image .hl-text2 {
		font-size: @wp-fs-mobile-h3;
	}
}
