.signature {
	width: 100%;
	text-align: right;
	position: relative;
	//font: italic 18px/1 @font-fancy;
	font-family: @font-handdrawn;
	letter-spacing: @font-handdrawn-letterspacing;
	font-size: 52px;
	color: @color-signature;
	transform: rotate(-3deg);
	margin-top: 50px;
	margin-bottom: 30px;
	padding-right: 30px;
}

// NOTE locale exception no yellow arrow for the italian website
html[lang="de"] .signature:before {
	content: '';
	background: url('/img/wp/klammer.svg') no-repeat;
	position: absolute;
	right: 0px;
	top: -5px;
	transform: rotate(25deg);
	width: 45px;
	height: 18px;
	display: block;
	background-size: cover;
}
