.Table {
	font-family: @font-condensed;
	overflow: auto;
	width: fit-content;
	max-width: 100%;
	// NOTE this fixes chrome counting the height of .Table-content for its page height
	// and therefore displaying large. empty, scrollable space under the table
	// https://stackoverflow.com/questions/22898335/strange-behavior-of-overflow-auto-on-chrome
	position: relative;
}

.Table-mini {
	font-size: @fs-s;
}

.Table-mini .TableCell:not(.TableCell-padding-0) { padding: 5px; }

.Table-padding-10 .TableCell:not(.TableCell-padding-0) { padding: 10px; }

.Table-content {
	width: fit-content;
	//border: 1px solid @color-table-border;
}

.TableRow {
	display: flex;
}

.TableBody .TableRow {
	border-top: 2px solid @color-table-inner-border;
	border-bottom: 2px solid transparent;
}

.TableBody .TableRow:hover {
	border-top: 2px solid @color-table-hover-border;
	border-bottom: 2px solid @color-table-hover-border;
	background: @color-table-hover-bg;
}

.Table-rowborder-1 .TableBody .TableRow {
	border-top: 1px solid @color-table-inner-border;
	border-bottom: 1px solid transparent;
}

.Table-rowborder-1 .TableBody .TableRow:hover {
	border-top: 1px solid transparent;
	border-bottom: 1px solid @color-table-inner-border;
}

.TableRow-24 { height: 24px; }
.TableRow-40 { height: 40px; }
.TableRow-54 { height: 54px; }
.TableRow-90 { height: 90px; }
// for use together with rotated labels
.TableRow-200 { height: 200px; }
.TableRow-240 { height: 240px; }

.TableHeader {
	position: sticky;
	top:0;
	left:0;
	z-index: 1;
	background: @color-background;
	border-bottom: 1px solid @color-table-header-border;
	font-weight: @font-weight-medium;
}

.TableBody {
	background: @color-white;
	font-weight: @font-weight-normal;
}

.TableSeparator {
	flex-shrink:0;
	background: @color-background;
	position: relative;
	border-left-width: 1px;
	border-right-width: 1px;
}

.TableHeader .TableSeparator {
	border-color: transparent;
}
.TableBody .TableSeparator {
	border-color: @color-table-border;
}

// HACK to conceal the borders from TableRow
.TableSeparator:before {
	content: '';
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 5px;
	display: block;
	background: @color-background;
}

.TableSeparator-20 {
	width: 20px;
}

.TableCell {
	flex-shrink:0;
}

.Table-colborder .TableBody .TableCell:not(:first-child) {
	border-left: 1px solid @color-table-inner-border;
}

.Table-colborder .TableHeader .TableCell:not(:first-child) {
	border-left: 1px solid transparent;
}

.TableCell-24		{ width: 24px; }
.TableCell-30		{ width: 30px; }
.TableCell-40		{ width: 40px; }
.TableCell-50		{ width: 50px; }
.TableCell-60		{ width: 60px; }
.TableCell-70		{ width: 70px; }
.TableCell-80		{ width: 80px; }
.TableCell-90		{ width: 90px; }
.TableCell-100		{ width: 100px; }
.TableCell-120		{ width: 120px; }
.TableCell-150		{ width: 150px; }
.TableCell-180		{ width: 180px; }
.TableCell-240		{ width: 240px; }
.TableCell-300		{ width: 300px; }
.TableCell-360		{ width: 360px; }
.TableCell-max		{ flex-grow: 1; }
.TableCell-unset	{ width: fit-content; }

.TableCell-padding-0	{ padding: 0px; }
.TableCell-padding-5	{ padding: 5px; }
.TableCell-padding-10	{ padding: 10px; }

.TableCell-align-right,
.TableCell-align-center,
.TableCell-valign-bottom,
.TableCell-valign-center,
.TableCell-valign-baseline {
	display: flex;
}

.TableCell-align-right		{
	justify-content: flex-end;
	text-align: right;
}
.TableCell-align-center		{ justify-content: center;	}

.TableCell-valign-bottom	{ align-items: flex-end;	}
.TableCell-valign-center	{ align-items: center;		}
.TableCell-valign-baseline	{ align-items: baseline;	}

.SortButton {
	display: flex;
	justify-content: space-between;
	align-items: inherit;
	width: 100%;
	height: 100%;
	padding: @padding-s;
}

.SortButton:hover {
	background: @color-table-sort-hover;
}

.SortButton--unsorted .fa {
	opacity: 0;
	visibility: hidden;
}

.SortButton--unsorted:hover .fa {
	opacity: 1;
	visibility: visible;
}

.SortButton .fa {
	margin-left: 3px;
	// when no caret is displayed, we still want to retain our width as not to change hot the label is broken into lines
	width: 10px;
}

// This mas to be used together with a row height 200
/*
<Row height={200}>
	<Cell customClass="TableCell--rotated-anchor">
		<div class="TableCell--rotated">.../div>
*/

.TableCell--rotated-anchor {
	position: relative;
}

// Use in child div of
//
.TableCell--rotated {
	position: absolute;
	transform: rotate(-45deg);
	transform-origin: left;
	bottom: 0;
	left: 30px;
	width: 260px;
	height: 30px;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
	padding: 10px 8px;
	border-bottom: 1px solid @color-table-header-border;
	// necessary to capture hover on element
	z-index: 1;
}
