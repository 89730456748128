.LayoutWrapper {
	display: flex;
	flex-flow: column;
	gap: @padding-xl;
}

.LayoutRow {
	display: flex;
	gap: @padding-xl;
}

.LayoutCell--equal {
	flex-basis: 0;
	flex-grow: 1;
}

.LayoutCell--one-third {
	width: 33%;
}

.LayoutCell--two-third {
	width: 67%;
	// NOTE one flex-shrink is needed to ensure equal width when columns
	// are used as several rows as on the migration page
	// left column can deal with shrinkage there better than the right
	flex-shrink: 0;
}

.LayoutCell--grow { flex-grow: 1; }

.LayoutCell--full { width: 100%; }

@media screen and (max-width: @breakpoint-mobile-max) {
	.LayoutRow {
		flex-flow: row wrap;
	}
	.LayoutCell--equal {
		width: 100%;
		flex-basis: auto;
	}
}
