
// TODO scroll snap if we have time
.routegallery {
	display: flex;
	overflow-x: auto;
}

.routegallery-image {
	object-fit: contain;
	width: @routegallery-image-width;
	max-height: @routegallery-image-width * 0.6;
}

// Only Widesrceen Desktop
@media screen and (min-width: 1840px) {
	.routegallery {
		justify-content: center;
	}
	.routegallery-image:not(:first-child) {
		margin-left: @padding-m;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.routegallery {
		margin: @padding-l 0;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.routegallery {
		margin: @padding-m 0;
	}
}

// Only Mobile
@media screen and (max-width: 520px) {
	// Smaller images to indicate that there is not only one image, but a scrollable galllery
	.routegallery-image {
		width: 80vw;
	}
}
