.image {
	width: 100%;
	//margin-top: @padding-m;
	margin-bottom: @padding-m;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-tag {
	max-width: 100%;
	margin-bottom: @padding-m;
}

.image-caption {
	font-weight: @font-weight-bold;
	line-height: @line-height;
	width: 100%;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.image.left,
	.image.right {
		width: 40%;
		margin-bottom: @padding-m;
	}

	.image.left {
		float: left;
		margin-right: @padding-m;
	}

	.image.right {
		float: right;
		margin-left: @padding-m;
	}

}
// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
}
