@search-clear-width: 30px;

.Search {
	width: 420px;
	position: relative;
	margin-bottom: 0;
}

.Search-input {
	.input-text;
	padding-right: @search-clear-width;
	height: @search-height;
}

.Search-clear {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: @search-clear-width;
	font-size: 18px;
	opacity: .5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Search-clear:hover,
.Search-clear:active {
	opacity: 1;
}
