// screen-reader utilities
// -------------------------

// only display content to screen readers. screen-reader-text is the class used by wordpress
.sr-only,
.screen-reader-text,
.fa-sr-only {
  .fa-sr-only();
}

// use in conjunction with .sr-only to only display content when it's focused
.sr-only-focusable,
.fa-sr-only-focusable {
  .fa-sr-only-focusable();
}
