.PopupAnchor {
	position: relative;
	cursor: default;
}

.PopupAnchor:hover .Popup {
	visibility: visible;
	opacity: 1;
}

.Popup {
	.arrow-box;
	.arrow-box-top;

	visibility: hidden;
	opacity: 0;
	position: absolute;
	z-index: 1;
	transition: all .2s ease-in;
}

.Popup-content {
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}
