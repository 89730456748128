//-----------------------------------------------------------------------------
//## form

.FormWrapper {
	width: 100%;
}

.FormRow {
	display: flex;
	gap: @padding-m;
}

.FormRow:not(:last-child) {
	margin-bottom: @padding-m;
}

.FormCell--equal {
	flex-basis: 0;
	flex-grow: 1;
}

.FormCell--one-third {
	width: 33%;
}

.FormCell--two-third {
	width: 67%;
	// NOTE one flex-shrink is needed to ensure equal width when columns
	// are used as several rows as on the migration page
	// left column can deal with shrinkage there better than the right
	flex-shrink: 0;
}

.FormCell--grow { flex-grow: 1; }

//-----------------------------------------------------------------------------
//## field

.FormField {
	width: 100%;
}

/*
These variants exist but are at the moment not referenced anywhere

.FormField--area
.FormField--checkbox
.FormField--color
.FormField--date
.FormField--datetimelocal
.FormField--display
.FormField--radio
.FormField--select
.FormField--text
*/

.FormField-label {
	// this is necessary for the "required" asterisk
	position: relative;
	display: inline-block;
}

.FormField-editor {
	// necessary for positioning the FieldError
	position: relative;
}

.FormField-hint {
	font-size: @fs-s;
	line-height: @line-height-s;
	margin-top: @padding-s;
}

//-----------------------------------------------------------------------------
//## required

.FormField--required .FormField-label:after {
	content: "*";
	display: block;
	position: absolute;
	font-size: @fs-4;
	right: -10px;
	top: -2px;
}

//-----------------------------------------------------------------------------
//## error

/*
Warning: if you use this in an overflow-hidden container the outlines will be cut off, so
add enough padding to avoid this
*/
.FormField--invalid .FormField-error-outline {
	outline: 2px solid @color-formerror;
	outline-offset: 2px;
}

@media screen and (max-width: @breakpoint-tablet-max) {
	.FormField--invalid .FormField-error-outline {
		outline-width: 1px;
		outline-offset: 0;
	}
}

.FormField-error {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 0s, opacity .3s ease 0s;

	position: absolute;
	top: -@input-height - 12;
	left: 0;

	color: @color-white;
	background: @color-formerror-message;
	border: 3px solid @color-formerror-message;
	border-radius: @border-radius;
	padding: @padding-s @padding-m;
	filter: drop-shadow(@box-drop-shadow);
	white-space: nowrap;
	z-index: 1;

	/*
	NOTE set something like this when the app has a header which obscures scrolling content
	scroll-margin-top: @app-header-height;
	*/
}

.FormField--invalid:focus-within .FormField-error {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s linear .3s, opacity .3s ease-out 0s;
}

.FormField--invalid:focus-within .FormField-error:after,
.FormField--invalid:focus-within .FormField-error:before {
	position: absolute;
	top: 100%;
	left: 20px;
	height: 0;
	width: 0;
	content: " ";
	border-style: solid;
	border-color: transparent;
	border-top-color: @color-formerror-message;
	pointer-events: none;
}

.FormField--invalid:focus-within .FormField-error:after {
	border-width: 10px;
	margin-left: -10px;
}
.FormField--invalid:focus-within .FormField-error:before {
	border-width: 14px;
	margin-left: -14px;
}

//-----------------------------------------------------------------------------
//## focus

/*
to scroll to and show an error in the form, this can be used to set the focus.
these elements are purely functional, do not style them!

.FormField-focusTarget {}
*/

/*
some form fields do not have an input field we can set the focus on -
multiple so we cannot choose. this element is used instead.
*/
.FormField-focusSink {
	.generic-focusSink;
}
