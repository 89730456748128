
.routeoverview .hl-2 {
	margin-top: 0;
}

.routeoverview-image {
	width: 100%;
}

.routeoverview-button {
	margin: @padding-m 0;
}

.routeoverview-up-link {
	text-align: right;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.routeoverview {
		padding: @padding-l;
		width: @content-width + 2 * @padding-l;
		margin: 0 auto @padding-l;
	}
	.routeoverview-text {
		display: flex;
		gap: 2* @padding-l;
	}
	.routeoverview-col1 {
		width: 55%;
	}
	.routeoverview-col2 {
		width: 45%;
	}
	.routeoverview-description-tablet-mobile {
		display: none;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.routeoverview {
		padding: @padding-m;
	}
	.routeoverview-col1 {
		width: 45%;
	}
	.routeoverview-col2 {
		width: 55%;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.routeoverview {
		border-radius: @border-radius-l;
		background-color: @color-white;
	}
	.routeoverview-col-wrapper {
		display: flex;
		gap: @padding-m;
	}
	.routeoverview-image-mobile {
		display: none;
	}
	.routeoverview-col2 {
		display: flex;
		flex-flow: column;
		justify-content: space-around;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.routeoverview {
		margin-bottom: @padding-m;
	}
	.routeoverview-description-desktop {
		display: none;
	}
}
// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.routeoverview {
		color: @color-white;
		flex-direction: column;
	}
	.routeoverview-headline {
		display: flex;
		flex-direction: column;
	}
	.routeoverview-headline-text2 {
		color: @color-white;
	}
	.routeoverview-image {
		background-color: @color-white;
		border-radius: @border-radius-l;
		margin-bottom: @padding-m;
	}
	.routeoverview-image-desktop-tablet {
		display: none;
	}
}
