// .Page {}

.PageHeader {
	width: 100%;
	margin-bottom: @padding-l;
}

.PageHeader-breadcrumbs {
	margin-bottom: @padding-s;
	height: @fs-s;
	font-size: @fs-s;
	line-height: 1;
}

.PageHeader-headline {
	display: flex;
	justify-content: space-between;
}

.PageHeader-title {
	font-size: @fs-1;
	font-weight: @font-weight-medium;
	font-family: @font-condensed;
	color: @color-heading;
}

.PageHeader-children {
	display: flex;
	gap: @padding-m;
	justify-content: flex-end;
	flex-grow: 1;
}

.PageBody {
	display: flex;
	flex-direction: column;
	// TODO frontend it might be better to flex the Page, but then we have to change even more in the Migration
	height: @page-height;
}

// TODO frontend this is a bit of a hack, we should have a class for full-page tables
.PageBody .Table {
	flex-grow: 1;
}
