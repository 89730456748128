.p {
	line-height: @line-height;
	margin-bottom: @padding-s;
}

.p + .hl-wrapper {
	margin-top: @padding-l;
}

.p-center {
	text-align: center;
}

.p-right {
	text-align: right;
}

.p-small {
	font-size: @wp-fs-s;
}
