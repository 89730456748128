/* RESET */
*, *:before, *:after { box-sizing: border-box; }

html {
	text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0;
	padding: 0;
}

p, h1, h2, h3, h4, h5, h6 {
	overflow-wrap: break-word;
}

h1, h2, h3, h4, h5, h6, th {
	font-weight: inherit;
	font-size: inherit;
// nicer line lengths in headlines, not supported on safari yet
	text-wrap: balance;
}

ol, ul { list-style-type: none; }

// seems obsolete nowadays, comment out if it turns out to be still needed
/*
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*/

a {
	text-decoration: none;
	text-decoration-skip-ink: auto;
	color: inherit;
}

button, select, input, textarea, pre {
	font: inherit;
	text-align: inherit;
}

button {
	appearance: none;
	border: none;
	background: none;
}

img, picture, video, canvas, svg {
	display: block;
	max-width: 100%;
}

// frame blockquotes in the correct quote characters according to language attribute of the document
// or set individually with <blockquote lang="fr"> (<q></q> already quote correctly)
// https://www.stefanjudis.com/today-i-learned/how-to-use-language-dependent-quotes-in-css/

blockquote::before {
	content: open-quote;
}

blockquote::after {
	content: close-quote;
}
