.animated-03 {
	animation-duration: 1s;
	animation-fill-mode: both;
}

.animated-05 {
	animation-duration: 1s;
	animation-fill-mode: both;
}

.animated,
.animated-10 {
	animation-duration: 1s;
	animation-fill-mode: both;
}

@media print, (prefers-reduced-motion: reduce) {
	.animated {
		animation-duration: 0ms;
		transition-duration: 0ms;
		animation-iteration-count: 0;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.fadeIn {
	animation-name: fadeIn;
}

@keyframes shakeX {
	from,
	to {
		transform: translate3d(0, 0, 0);
	}

	10%,
	30%,
	50%,
	70%,
	90% {
		transform: translate3d(-3px, 0, 0);
	}

	20%,
	40%,
	60%,
	80% {
		transform: translate3d(3px, 0, 0);
	}
}

.shakeX {
	animation-name: shakeX;
}
