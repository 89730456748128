.MessageArea {
	display: flex;
	justify-content: center;
	flex-flow: column;
	align-items: center;
}

.Message {
	.generic-box;
	padding-right:@icon-size-small + @padding-m;
	position: relative;
	max-width: 60%;
	max-height: 50vh;
	overflow-y: auto;
}

.Message + .Message {
	margin-top: @padding-s;
}

.Message--error {
	.generic-box--error;
}

.Message--warning {
	.generic-box--warning;
}
.Message--info {
	.generic-box--info;
}

.Message-close {
	position: absolute;
	top: @padding-xs;
	right: @padding-xs;
}

// NOTE we could use multiple divs instead this
.Message-content {
	white-space: pre-line;
}
