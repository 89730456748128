@input-height:				36px;

// This is necessary because Safari on iOS autozooms into form controls with smaller font sizes
@input-font-size:			16px;

@input-checkbox-size:		20px;
@input-radio-size:			16px;

@input-color-palette-gap:	4px;
@input-label-margin:		@padding-xs;

@input-counter-size:		34px;
@input-counter-number-size:	36px;
@input-counter-number-size--small:	24px;

//-----------------------------------------------------------------------------
// label

.input-label {
	font-weight: @font-weight-medium;
	color: @color-label;
	margin-bottom: @input-label-margin;
}

//-----------------------------------------------------------------------------
// internal mixins

.input-textual {
	width: 100%;
	font-size: @input-font-size;
	color: @color-text;
	padding: @padding-xs;
	border: 1px solid @color-input-border;
	border-radius: @border-radius;
	box-shadow: @inner-shadow;
	transition: box-shadow 0.3s;
}

.input-textual-singleline {
	.input-textual;
	height: @input-height;
}

.input-textual-multiline {
	.input-textual;
	height: @input-height * 3;
	line-height: @line-height-s;
}

//-----------------------------------------------------------------------------
//## textfields

.input-text {
	.input-textual-singleline;
}

.input-text::placeholder {
	color: @color-light;
	font-weight: @font-weight-normal;
}

.input-date {
	.input-textual-singleline;
}

.input-datetime {
	.input-textual-singleline;
}

.input-textarea {
	.input-textual-multiline;
}

//-----------------------------------------------------------------------------
//## select

.input-select-wrapper {
	position: relative;
}

.input-select {
	.input-textual;

	appearance: none;
	background: @color-white;
	line-height: @line-height;
	padding: 0 @padding-xs;
	height: @input-height;
}

.input-select-wrapper .fa {
	position: absolute;
	top: 6px;
	right: 9px;
	height: 100%;
	font-size: @fs-base * 1.5;
	color: @color-primary;
	text-align: center;
	pointer-events: none;
	z-index: 1;
}

//-----------------------------------------------------------------------------
//## checkbox

.input-checkbox-wrapper {
	display: flex;
	align-items: baseline;
}

.input-checkbox-label {
	flex-grow: 1;
	line-height: @line-height-s;
}

.input-checkbox {
	appearance: none;
	position: relative;
	top: 3px;
	width: @input-checkbox-size;
	height: @input-checkbox-size;
	margin-right: @padding-s;
	background: @color-white;
	border: 1px solid @color-input-border;
	border-radius: @border-radius-s;
	flex-grow: 0;
	flex-shrink: 0;
}

.input-checkbox:checked:before {
	content: '×';
	display: block;
	position: absolute;
	left: -1px;
	top: -4px;
	width: @input-checkbox-size;
	color: @color-primary;
	font-size: 24px;
	font-weight: @font-weight-bold;
	line-height: 1;
	text-align: center;
}
.input-checkbox:disabled:checked:before {
	color: @color-disabled;
}

//-----------------------------------------------------------------------------
//## radio

.input-radio-wrapper {
	display: flex;
	align-items: baseline;
	margin-bottom: @padding-s;
}

.input-radio {
	appearance: none;
	border-radius: 50%;
	width: @input-radio-size;
	height: @input-radio-size;
	background: @color-white;
	box-shadow: 0 0 0 2px hsl(0, 0%, 100%), 0 0 0 3px @color-input-border;
	margin-right: @padding-s;
	flex-grow: 0;
	flex-shrink: 0;
}

.input-radio:checked {
	background: @color-primary;
}

// .input-radio-label {}

//-----------------------------------------------------------------------------
//## color

.input-color-palette {
	// necessary for styling the field if invalid
	border-radius: @border-radius;
	padding: 2px;

	display: flex;
	flex-direction: column;
	gap: @input-color-palette-gap;
}

.input-color-palette-row {
	display: flex;
	gap: @input-color-palette-gap;
}

.input-color-wrapper {
	width: calc(@input-height / 2);
	height: calc(@input-height / 2);
}

.input-color {
	appearance: none;
	margin: 0;
	width: 100%;
	height: 100%;;
	border-radius: @border-radius-s;
}

.input-color:checked {
	outline: 2px solid @color-primary;
	outline-offset: 1px;
}

//-----------------------------------------------------------------------------
//## CounterField with label

.input-counter-wrapper {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: @padding-s;
}

.input-counter-wrapper .input-label {
	margin-bottom: 0;
}

//-----------------------------------------------------------------------------
//## counter standalone

.input-counter {
	display: flex;
	align-items: center;
	border: 1px solid @color-input-border;
	// NOTE this cannot be set to 50% because box is not round
	border-radius: (@input-counter-size / 2);
}

.input-counter-button {
	width: @input-counter-size;
	height: @input-counter-size;
	border-radius: 50%;
	border: none;
	text-align: center;
}
.input-counter-button:hover,
.input-counter-button:active {
	background: @color-primary;
	color: @color-white;
}

.input-counter-number {
	font-weight: @font-weight-normal;
	color: @color-text;
	text-align: center;
	width: @input-counter-number-size;
	font-size: @fs-4;
}

.compact {
	.input-counter-number {
		width: @input-counter-number-size--small;
		font-size: @fs-base;
	}
}
