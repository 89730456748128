.testimonials {
	background: @color-brand-grey;
}

.testimonials-main {
	position: relative;
}

.testimonials-title {
	flex-shrink: 0;
}

.testimonials-title,
.testimonials-title .hl-text2 {
	color: @color-white;
}

.testimonials-main::before {
	content: "";
	display: block;
	position: absolute;
	background: linear-gradient(to right, @color-brand-grey, rgba(145,142,122,0));
	z-index: 1;
}

.testimonials-main::after {
	content: "";
	display: block;
	position: absolute;
	background: linear-gradient(to left, @color-brand-grey, rgba(145,142,122,0));
}

.testimonials-content {
	flex-grow: 1;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	position: relative;
	scrollbar-width: none;
}

// hide scrollbar but needs also 'scrollbar-width: none;' as above
.testimonials-content::-webkit-scrollbar {
	display: none;
}

.testimonials-single-testimonial {
	background-color: @color-white;
	flex-shrink: 0;
	border-radius: @border-radius;
	scroll-snap-align: center;
}

.testimonials-image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.testimonials-texts-wrapper{
	line-height: @line-height;
}

.testimonials-traveldate {
	text-align: right;
	margin-bottom: @padding-s;
}

.testimonials-name {
	font-weight: @font-weight-bold;
}
.testimonials-location,
.testimonials-traveldate {
	color: @color-brand-grey;
}

.testimonials-location {
	margin-bottom: @padding-s;
}

.testimonials-text {
	max-height: 200px;
}

.testimonials-buttons {
	margin-top: @padding-l;
	display: flex;
	justify-content: center;
	gap: @padding-l;
}

.touch .testimonials-buttons{
	display: none;
}

.touch .testimonials-text {
	overflow: scroll;
}

.no-touch .testimonials-text {
	overflow: hidden;
	transition: all .3s ease-in;
}

.no-touch .testimonials-single-testimonial:hover .testimonials-text {
	max-height: 600px;
}

.testimonials-button {
	opacity: .5;
}

.testimonials-button-active {
	opacity: 1;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	/* variables for desktop */
	@testimonial-title-desktop-width: 25%;
	@single-testimonial-desktop-width: 330px;

	.testimonials {
		padding: 2 * @padding-l;
	}
	.testimonials-main::before {
		left: @testimonial-title-desktop-width;
		width: calc((100% - @testimonial-title-desktop-width - @single-testimonial-desktop-width - 2* @padding-l)/2);
		height: 100%;
	}
	.testimonials-main::after {
		right: 0;
		width: calc((100% - @testimonial-title-desktop-width - @single-testimonial-desktop-width - 2* @padding-l)/2);
		height: 100%;
	}
	.testimonials-title {
		width: @testimonial-title-desktop-width;
		// = imageheight + padding + datemarginbottom + (dateheight + nameheight + locationheight) * lineheight + marginbottom + (textheight - (headline1 - headline2) * lineheight - padding)
		// = 132 + 20 + 10 + (16 + 16 + 16) * 1.45 + 10 + (200 - (39 + 39) * 1.1 - 20)= 335.8
		margin-top: 335.8px;
	}
	.testimonials-single-testimonial {
		width: @single-testimonial-desktop-width;
	}
	.testimonials-single-testimonial:not(:first-child) {
		margin-left: @padding-l;
	}
	.testimonials-single-testimonial:first-child {
		margin-left: calc((100% - @single-testimonial-desktop-width) / 2);
	}
	.testimonials-single-testimonial:last-child {
		margin-right: calc((100% - @single-testimonial-desktop-width) / 2);
	}
	.testimonials-image {
		width: @single-testimonial-desktop-width;
		height: @single-testimonial-desktop-width * 0.4;
	}

}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	/* variables for tablet */
	@testimonial-title-tablet-width: 30%;
	@single-testimonial-tablet-width: 330px;

	.testimonials {
		padding: 2 * @padding-m 0;
	}
	.testimonials-main::before {
		left: @testimonial-title-tablet-width;
		width: calc((100% - @testimonial-title-tablet-width - @single-testimonial-tablet-width - 2* @padding-m)/2);
		height: 100%;
	}
	.testimonials-main::after {
		right: 0;
		width: calc((100% - @testimonial-title-tablet-width - @single-testimonial-tablet-width - 2* @padding-m)/2);
		height: 100%;
	}
	.testimonials-title {
		width: @testimonial-title-tablet-width;
		// = imageheight + padding + datemarginbottom + (dateheight + nameheight + locationheight) * lineheight + marginbottom + (textheight - (headline1 - headline2) * lineheight - padding)
		// = 132 + 20 + 10 + (16 + 16 + 16) * 1.45 + 10 + (200 - (33 + 33) * 1.1 - 20)= 332.2
		margin-top: 333.2px;
	}
	.testimonials-single-testimonial {
		width: @single-testimonial-tablet-width;
	}
	.testimonials-single-testimonial:not(:first-child) {
		margin-left: @padding-m;
	}
	.testimonials-single-testimonial:first-child {
		margin-left: calc((100% - @single-testimonial-tablet-width) / 2);
	}
	.testimonials-single-testimonial:last-child {
		margin-right: calc((100% - @single-testimonial-tablet-width) / 2);
	}
	.testimonials-image {
		width: @single-testimonial-tablet-width;
		height: @single-testimonial-tablet-width * 0.4;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	/* variables for mobile */
	@single-testimonial-mobile-width: 250px;
	@top-down: 2* @wp-fs-mobile-h3 * @line-height-xs + @padding-s;

	.testimonials {
		padding: 2 * @padding-i 0;
	}
	.testimonials-single-testimonial {
		width: @single-testimonial-mobile-width;
	}
	.testimonials-single-testimonial:not(:first-child) {
		margin-left: @padding-i;
	}
	.testimonials-single-testimonial:first-child {
		margin-left: calc((100% - @single-testimonial-mobile-width) / 2);
	}
	.testimonials-single-testimonial:last-child {
		margin-right: calc((100% - @single-testimonial-mobile-width) / 2);
	}
	.testimonials-image {
		width: @single-testimonial-mobile-width;
		height: @single-testimonial-mobile-width * 0.4;
	}
	.testimonials-texts-wrapper {
		padding: @padding-i;
	}
	.testimonials-main::before {
		top: @top-down;
		left: 0;
		width: calc((100% - @single-testimonial-mobile-width - 2* @padding-i)/2);
		height: calc(100% - @top-down);
	}
	.testimonials-main::after {
		top: @top-down;
		right:0;
		width: calc((100% - @single-testimonial-mobile-width - 2* @padding-i)/2);
		height: calc(100% - @top-down);
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.testimonials-main {
		display: flex;
	}
	.testimonials-texts-wrapper {
		padding: @padding-m;
	}
}
