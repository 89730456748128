.partner {}

.partner li {
	display: inline-block;
	width: @width-xs;
	height: 80px;
	margin: @padding-xs;
}
.partner a {
	background-image: url('/img/common/partner-sprite.png');
	display: inline-block;
	outline: 5px solid @color-brand-grey;
	// NOTE this is necessary because of the border
}
.partner a:hover, .partner a:active {
	background-color: @color-white;
	outline: 5px solid @color-white;
}

.partner a span { display: none; }

.partner-aer{width: 112px; height: 56px; background-position: -0px 0px;}
.partner-aer:hover{width: 112px; height: 56px; background-position: -113px 0px;}
.partner-allianz{width: 120px; height: 75px; background-position: -226px 0px;}
.partner-allianz:hover{width: 120px; height: 75px; background-position: -347px 0px;}
.partner-csr{width: 70px; height: 70px; background-position: -468px 0px;}
.partner-csr:hover{width: 70px; height: 70px; background-position: -539px 0px;}
.partner-ergo{width: 86px; height: 46px; background-position: -610px 0px;}
.partner-ergo:hover{width: 86px; height: 46px; background-position: -697px 0px;}
.partner-forum{width: 61px; height: 76px; background-position: -784px 0px;}
.partner-forum:hover{width: 61px; height: 76px; background-position: -846px 0px;}
.partner-hospederias{width: 105px; height: 60px; background-position: -908px 0px;}
.partner-hospederias:hover{width: 105px; height: 60px; background-position: -1014px 0px;}
.partner-mmueller{width: 78px; height: 78px; background-position: -1120px 0px;}
.partner-mmueller:hover{width: 78px; height: 78px; background-position: -1199px 0px;}
.partner-paradores{width: 123px; height: 60px; background-position: -1278px 0px;}
.partner-paradores:hover{width: 123px; height: 60px; background-position: -1402px 0px;}
.partner-pousadas{width: 150px; height: 78px; background-position: -1526px 0px;}
.partner-pousadas:hover{width: 150px; height: 78px; background-position: -1677px 0px;}
