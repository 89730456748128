.routeofferbutton {
	display: flex;
	justify-content: center;
	padding-top: 50px;
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.routeofferbutton-button {
		width: 80%;
		margin-bottom: @padding-m;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.routeofferbutton-button {
		width: @content-width * 0.6;
	}
}
