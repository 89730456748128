.SideBar {
	display: flex;
	flex-flow: row nowrap;
	gap: @padding-m;
}
.SideBar--align-left {
	justify-content: flex-start;
}
.SideBar--align-right {
	justify-content: flex-end;
}
