// territorybox.less and routebox.less should use the same base layout (font-sizes etc)
// TODO css use mixins

.routebox-wrapper {
	margin-bottom: 2 * @padding-l;
}

.routebox {
	position: relative;
	background-position: center;
	background-size: cover;
	color: @color-white;
	padding: @padding-m;
	border-radius: @border-radius-l;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
}

.routebox-content {
	filter: @text-shadow-white;
}

.routebox-hover {
	opacity: 0;
	visibility: hidden;
	transition: background .3s ease;
	position: absolute;
}

.routebox-tag {
	font-weight: @font-weight-bold;
	line-height: @line-height-s;
	margin-bottom: @padding-s;
}

.routebox-name,
.routebox-hover-short {
	font-weight: @font-weight-bold;
	margin-bottom: @padding-s;
}

// On larger devices
@media screen and (min-width: @breakpoint-mobile-small) {
	.routebox-wrapper {
		width: calc(100vw - var(--scrollbar-width));
		position: relative;
		left: 50%;
		right: 50%;
		margin-left: calc(-50vw + var(--scrollbar-width) / 2);
		margin-right: calc(-50vw + var(--scrollbar-width) / 2);
	}

	.routebox-center {
		display: flex;
		justify-content: center;
	}

	.routebox-grid {
		display: grid;

		grid-auto-columns: @routebox-desktop-width;
		//flex-flow: row nowrap;
		gap: @padding-m;
		grid-auto-flow: column;
		overflow-x: auto;
	}

	.routebox-wrapper.one-row .routebox-grid{
		grid-template-rows: repeat(1,1fr);
	}
	.routebox-wrapper.two-row .routebox-grid{
		grid-template-rows: repeat(2,1fr);
	}
	.routebox {
		width: @routebox-desktop-width;
		height: @routebox-desktop-width;
		//flex-shrink: 0;
	}

	.routebox-tag {
		font-size: @wp-fs-h3;
	}

	.routebox:hover {
		justify-content: flex-start;
		padding: 0;
	}

	.routebox:hover .routebox-content {
		display: none;
	}

	.routebox:hover .routebox-hover {
		position: static;
		opacity: 1;
		visibility: visible;
		border-radius: @border-radius-l;
		padding: @padding-m;
		height: 100%;
		background: rgba(36, 40, 42, 0.8);
		display: flex;
		flex-flow: column;
	}

	.routebox-hover-long {
		flex-grow: 1;
		margin-bottom: @padding-s;
	}

	.routebox-hover-short,
	.routebox-hover-long {
		line-height: @line-height;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-mobile-small) and (max-width: @breakpoint-tablet-max) {
	.routebox-tag{
		font-size: @wp-fs-tablet-h5;
	}
}

// On small mobile sizes
@media screen and (max-width: @breakpoint-mobile-small - 1) {
	.routebox {
		width: 100%;
		padding-top: 60%;
		margin-bottom: @padding-l;
	}
	.routebox-tag {
		font-size: @wp-fs-mobile-h3;
	}
}
