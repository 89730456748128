.concept {
	width: 100%;
}

.concept-item {
	border-radius: @border-radius-l;
	background: @wp-concept-bg-color;
	border: 1px solid @color-brand-grey;
	padding: @padding-s;
	flex-grow: 1;
}

.concept-item-header {
	display: flex;
	align-items: center;
	gap: @padding-xs;
	margin-bottom: @padding-i;
}

.concept-item-icon {
	height: 80px;
	aspect-ratio: 61.16 / 80;
	padding: @padding-s;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.concept-item-icon-1 { background-image: url('/img/wp/ablauf01.svg'); }
.concept-item-icon-2 { background-image: url('/img/wp/ablauf02.svg'); }
.concept-item-icon-3 { background-image: url('/img/wp/ablauf04.svg'); }

.concept-item-title-black {}

.concept-item-title-grey {
	color: @color-brand-grey;
	font-weight: @font-weight-normal;
}

.concept-item-text {
	line-height: @line-height-m;
}

// -------------------------------------------------------------------------------
// Desktop and Tablet
@media screen and (min-width: @breakpoint-tablet-min) {
	.concept {
		display: flex;
		gap: @padding-l;
		margin-bottom: 90px;
	}
}

// -------------------------------------------------------------------------------
// Tablet
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max)  {
	.concept {
		gap: @padding-m;
	}
}

// -------------------------------------------------------------------------------
// Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.concept {
		margin-bottom: @padding-xxl;
	}
	.concept-item:not(:last-child) {
		margin-bottom: @padding-l;
	}
	.concept-item-icon {
		height: 60px;
	}
}
