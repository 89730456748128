// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.questionnaire-wrapper {
		position: absolute;
	}
	.questionnaire {
		position: absolute;
		top: -300px;
		width: 40%;
	}
	.breadcrumbs + .questionnaire-wrapper .questionnaire {
		top: -340px;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.questionnaire-wrapper {
		width: @content-width;
		margin: 0 auto;
	}
	.questionnaire {
		right: 0;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.questionnaire-wrapper {
		width: calc(100% - @padding-m);
		position: absolute;
	}
	.questionnaire {
		right: @padding-l;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.landingpage-other .questionnaire {
		margin-top: -3 * @padding-l;
		margin-bottom: @padding-l;
		// necessary to be on top of optional hero-video
		position: relative;
	}

// On route pages questionnaire should be included (can be opened by the starter) but should not be visible on top
	.landingpage-route .App.integrated.visible {
		display: none;
	}
}
