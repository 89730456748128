// TODO geko compare geotoura and geko reset
@import "reset.less";

@import "@v3/geko/src/less/index-without-fontawesome.less";

// geko does need fontawesome, but we have our own - tell geko to use it
.fa {
	.fa-light;
}

@import "genericOverrides.less";

@import "variables.less";

@import "fonts/asap.less";
@import "fonts/nighttime.less";
@import "fontawesome/fontawesome.less";
@import "fontawesome/light.less";

// TODO geko replace by geko components and overrides
@import "buttons-and-links.less";

@import "cookie.less";
@import "signature.less";
// TODO geko this is only used by traveldata
// @import "simpleNav.less";
