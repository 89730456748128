.Box {
	.generic-box;
	width: 100%;
}

.Box + .Box {
	margin-top: @padding-s;
}

.Box--error {
	.generic-box--error;
}

.Box--warning {
	.generic-box--warning;
}
.Box--info {
	.generic-box--info;
}
