.regionbox {
	background: @color-brand-grey;
	background-image: url('/img/wp/topographic-dark-white.svg');
	// Viewbox size of svg
	background-size: 800px 1200px;
	margin: 0;
}

.regionbox-inner {
	padding: @padding-l 0;
	margin-bottom: 0; // override contentwidth
}

.regionbox-headline {
	color: @color-white;
}

.regionbox-text2 {
	color: @color-white;
}

.regionbox-content {
	display: flex;
	flex-wrap: wrap;
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.regionbox-content {
		flex-direction: column;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.regionbox-headline {
		display: flex;
		flex-direction: column;
	}
}
