@charset "utf-8";
@import "../../../common/src/less/index.less";

@import "inc/variables.less";

// Order imports below alphabetically
@import "inc/arrowbuttons.less";
@import "inc/background.less";
@import "inc/benefits.less";
@import "inc/benefititem.less";
@import "inc/blogsignature.less";
@import "inc/breadcrumbs.less";
@import "inc/clearfloat.less";
@import "inc/columns.less";
@import "inc/concept.less";
@import "inc/featuredroute.less";
@import "inc/float.less";
@import "inc/footer.less";
@import "inc/header.less";
@import "inc/headline.less";
@import "inc/hero.less";
@import "inc/hintbox.less";
@import "inc/hintitem.less";
@import "inc/image.less";
@import "inc/intro.less";
@import "inc/lexicon.less";
@import "inc/li.less";
@import "inc/link.less";
@import "inc/newsletterbox.less";
@import "inc/p.less";
@import "inc/partner.less";
@import "inc/post.less";
@import "inc/questionnaire.less";
@import "inc/regionbox.less";
@import "inc/regionitem.less";
@import "inc/routebox.less";
@import "inc/routegallery.less";
@import "inc/routeofferbutton.less";
@import "inc/routeoverview.less";
@import "inc/routeuplink.less";
@import "inc/routeuplinktexts.less";
@import "inc/section.less";
@import "inc/specialmoment.less";
@import "inc/starter.less";
@import "inc/tags.less";
@import "inc/territorybox.less";
@import "inc/testimonials.less";
@import "inc/topbutton.less";

// NOTE: we initialize scrollbar-width with some sensible default, real value is set by JS later
:root {
	--scrollbar-width: 16px;
}

body {
	font-family: 'Asap', sans-serif;
	font-size: @wp-fs-base;
	line-height: 1;
	background-color: @color-white;
	color: @color-body;
	// NOTE: fixes overflow issue with breakout containers - width 100vw - and scrollbars, might produce problems elsewhere
	overflow-x: hidden;
}

em {
	font-style: italic;
}

strong, b { font-weight: @font-weight-bold; }

#wpadminbar { display:block !important; }

// NOTE use this helper class for all elements which break out of the fixed width container
.fullwidth {
	position: relative;
	width: calc(100vw - var(--scrollbar-width));
	left: 50%;
	right: 50%;
	margin-left: calc(-50vw + var(--scrollbar-width) / 2);
	margin-right: calc(-50vw + var(--scrollbar-width) / 2);
}

@media screen and (min-width: @breakpoint-desktop-min) {
	.main,
	// Helper class
	.contentwidth {
		width: @content-width;
		margin: 0 auto;
	}
	.main {
		margin-bottom: @main-bottom-margin-desktop;
	}
}

.spacer-xxl {
	margin-top: @padding-l * 2;
}
// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.main,
	.contentwidth {
		width: 100%;
		padding-left: @padding-m;
		padding-right: @padding-m;
	}

	.main {
		margin-bottom: @main-bottom-margin-tablet-mobile;
	}

	.spacer-xxl {
		margin-top: @padding-l;
	}
}

// Our Breakpoints
// Desktop >= 1200px
// Tablet 768px - 1199px
// Mobile < 768px

// Example Media Queries

/*
// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	h2 {
		color: blue !important;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	h2 {
		color: green !important;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	h2 {
		color: red !important;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	h2 {
		font-style: italic;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	h2 {
		font-style: italic;
	}
}
*/
