.post-flex {
	gap: @padding-m;
	padding-bottom: @padding-m;
}

.post {
	display: block;
	border: @padding-s solid transparent;
	transition: all .3s ease-in;
}

.no-touch .post:hover {
	background: @color-white;
	border-color: @color-white;
	filter: @button-shadow;
}

.post-allposts-button {
	background: @color-white;
}

// Styling for wordpress standard navigation the_posts_navigation()
.posts-navigation {
	display: flex;
	justify-content: center;
	width: 100%;
}

.posts-navigation a {
	font-weight: @font-weight-bold;
	text-decoration: underline;
	cursor: pointer;
}

.posts-navigation a:hover, .posts-navigation a:active {
	color: @color-highlight-text-hover;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.post-flex {
		display: flex;
		flex-flow: row wrap;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.post {
		width: calc(@content-width / 3 - (2 * @padding-l / 3) );
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.post {
		width: calc(33% - (2 * @padding-m / 3) );
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.post-blogcategory.post-wrapper {
		overflow-x: auto;
	}

	.post-blogcategory .post-flex {
		display: flex;
		flex-flow: row nowrap;
	}
	.post {
		width: 80%;
	}
	.post-archive .post {
		width: 100%;
	}
}

.post {
	flex-shrink: 0;
	flex-grow: 0;
}

.post-image {
	width: 100%;
	padding-bottom: 60%;
	margin-bottom: @padding-m;
	background-size: cover;
	background-repeat: no-repeat;
}

.post-title {
	font-weight: @font-weight-bold;
	margin-bottom: @padding-m;
	line-height: @line-height;
}

.post-excerpt{
	line-height: @line-height;
}

.post-link {
	text-align: end;
	padding-top: @padding-m;
}
