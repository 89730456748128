.section {
	line-height: @line-height;
}

.section-dark,
.section-dark .hl-text2 {
	color: @color-white;
}

// Necessary for pages like impressum and AGB which use lists
.section li:not(:last-child) {
	margin-bottom: @padding-s;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.section {
		width: @max-line-length-desktop;
	}
}
