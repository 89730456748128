//-----------------------------------------------------------------------------
// color pairings

.generic-fg--error {
	color: @colorset-fg-error;
}

.generic-fg--warn {
	color: @colorset-fg-warn;
}

.generic-fg--ok {
	color: @colorset-fg-ok;
}

.generic-fg--highlight {
	color: @color-primary;
}

.generic-bg--error {
	color: @color-white;
	background: @colorset-fg-error;
}

.generic-bg--warn {
	color: @color-white;
	background: @colorset-fg-warn;
}

.generic-bg--ok {
	color: @color-white;
	background: @colorset-fg-ok;
}

.generic-bg--highlight {
	color: @color-white;
	background: @color-primary;
}

//-----------------------------------------------------------------------------
// Base typography

.generic-box {
	padding: @padding-m;
	font-size: @fs-s;
	line-height: @line-height-s;
	border: 1px solid;
	box-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
	border-radius: @border-radius-s;
}

.generic-box--error {
	background:		@colorset-box-bg-error;
	border-color:	@colorset-box-border-error;
}

.generic-box--warning {
	background:		@colorset-box-bg-warning;
	border-color:	@colorset-box-border-warning;
}
.generic-box--info {
	background:		@colorset-box-bg-info;
	border-color:	@colorset-box-border-info;
}

//-----------------------------------------------------------------------------
// utilities

/*
to be used when something invisible is needed to take the focus
use with care, this is nothing but a hack
resembles .sr-only but with less baggage
*/
.generic-focusSink {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	opacity: 0;
}

//-----------------------------------------------------------------------------
// arrow-box

@arrow-box-size: 8px;
@arrow-box-outline: 1px;
@arrow-box-background: @color-white;
@arrow-box-border: @arrow-box-outline solid #d7d7d7;
@arrow-box-boxshadow: 2px 2px 3px rgb(0 0 0 / 30%);

.arrow-box {
	position: relative;
	color: @color-text;
	background: @arrow-box-background;
	border: @arrow-box-border;
	border-radius: @border-radius;
	box-shadow: @arrow-box-boxshadow;
	padding: @padding-s;
	font-size: @fs-xs;
	line-height: @line-height;

	&:after {
		position: absolute;
		width: calc(@arrow-box-size * 2);
		height: calc(@arrow-box-size * 2);
		background: inherit;
		border: inherit;
		border-right: none;
		border-bottom: none;
		content: ' ';
	}
}

.arrow-box-top {
	&:after {
		top: -@arrow-box-size;
		left: 10px;
		transform: rotate(0deg) translateY(-@arrow-box-outline) scaleX(0.5) rotate(45deg);
	}
}
.arrow-box-bottom {
	&:after {
		bottom: -@arrow-box-size;
		left: 10px;
		transform: rotate(180deg) translateY(-@arrow-box-outline) scaleX(0.5) rotate(45deg);
	}
}

.arrow-box-left{
	&:after {
		left: -@arrow-box-size;
		transform: rotate(270deg) translateY(-@arrow-box-outline) scaleX(0.5) rotate(45deg);
	}
}

.arrow-box-right{
	&:after {
		right: -@arrow-box-size;
		transform: rotate(90deg) translateY(-@arrow-box-outline) scaleX(0.5) rotate(45deg);
	}
}
