// territorybox.less and routebox.less should use the same base layout (font-sizes etc)
// TODO css use mixins

.territorybox-wrapper {
	overflow-x: auto;
	position: relative;
	width: calc(100vw - var(--scrollbar-width));
	left: 50%;
	right: 50%;
	margin-left: calc(-50vw + var(--scrollbar-width) / 2);
	margin-right: calc(-50vw + var(--scrollbar-width) / 2);
}

.territorybox-flex {
	display: flex;
	flex-flow: row nowrap;
}

.territorybox {
	position: relative;
	background-position: center;
	background-size: cover;
	color: @color-white;
	border-radius: @border-radius-l;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
}

.territorybox-content {
	filter: @text-shadow-white;
}

.territorybox-item-routes .territorybox:hover {
	justify-content: flex-start;
	padding: 0;
}

.territorybox-hover {
	opacity: 0;
	visibility: hidden;
	transition: background .3s ease;
	position: absolute;
}

.territorybox-item-routes .territorybox:hover .territorybox-content {
	display: none;
}

.territorybox-tag {
	font-weight: @font-weight-bold;
	line-height: @line-height-s;
	margin-bottom: @padding-s;
}

.territorybox-navigation {
	display: none;
}

// On larger devices
@media screen and (min-width: @breakpoint-mobile-small) {

	.territorybox-flex {
		gap: @padding-m;
	}

	.territorybox {
		padding: @padding-m;
	}

	.territorybox-item-routes {
		display: flex;
		flex-flow: row nowrap;
		gap: @padding-m;
		margin-top: @padding-m;
	}

	.territorybox-item-routes .territorybox:hover .territorybox-hover {
		position: static;
		opacity: 1;
		visibility: visible;
		border-radius: @border-radius-l;
		padding: @padding-m;
		height: 100%;
		background: rgba(36, 40, 42, 0.8);
		display: flex;
		flex-flow: column;
	}

	.territorybox-hover-long {
		flex-grow: 1;
		margin-bottom: @padding-s;
	}

	.territorybox-hover-short,
	.territorybox-hover-long {
		line-height: @line-height;
	}
	.territorybox-name,
	.territorybox-hover-short {
		font-weight: @font-weight-bold;
		margin-bottom: @padding-s;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.territorybox {
		width: @routebox-desktop-width;
		height: @routebox-desktop-width;
		//flex-shrink: 0;
	}

	.territorybox.large {
		width: 2 * @routebox-desktop-width + @padding-m;
		height: @territorybox-desktop-height;
	}
	.territorybox-tag {
		font-size: @wp-fs-h3;
	}
	.territorybox.large .territorybox-tag {
		font-size: @wp-fs-h1;
	}
	.territorybox.large .territorybox-name {
		font-size: @wp-fs-h5;
		margin-bottom: 2 * @padding-l;
	}

	// navigation with buttons instead of scrollbar
	.no-touch .territorybox-navigation {
		position: relative;
		display: block;
		pointer-events: none;
	}

	// hiding the scrollbar
	.no-touch .territorybox-wrapper {
		overflow-x: hidden;
	}

	.territorybox-navigation-inner {
		position: absolute;
		height: 1080px;
		bottom: 0;
		width: calc(100vw - var(--scrollbar-width));
		left: 0;
		right: 0;
	}

	.territorybox-button {
		position: absolute;
		width: 70px;
		height: 100%;
		top: 0;
		bottom: 0;
		background: rgba(255,255,255,0.7);
		pointer-events: auto;
	}
	.territorybox-button:not(.territorybox-button-inactive):hover,
	.territorybox-button:not(.territorybox-button-inactive):active {
		background: rgba(255,255,255,0.85);
	}

	.territorybox-button:not(.territorybox-button-inactive):hover .arrowbutton,
	.territorybox-button:not(.territorybox-button-inactive):active .arrowbutton {
		border-color: @color-highlight;
		background-color: @color-hightlight-bg-hover;
	}

	.territorybox-button-inactive {
		opacity: .5;
	}

	.territorybox-button.left {
		left: 0;
	}
	.territorybox-button.right {
		right: 0;
	}

	.territorybox-button .arrowbutton {
		position: absolute;
		top: 626px;
		left: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

}

// Only Tablets
@media screen and (min-width: @breakpoint-mobile-small) and (max-width: @breakpoint-tablet-max) {
	.territorybox-flex {
		margin-left: @padding-m;
	}
	.territorybox {
		width: @routebox-tablet-width;
		height: @routebox-tablet-width;
		//flex-shrink: 0;
	}

	.territorybox.large {
		width: 2 * @routebox-tablet-width + @padding-m;
		height: @territorybox-tablet-height;
	}
	.territorybox-tag {
		font-size: @wp-fs-tablet-h5;
	}
	.territorybox.large .territorybox-tag {
		font-size: @wp-fs-tablet-h2;
	}
	.territorybox.large .territorybox-name {
		font-size: @wp-fs-mobile-h5;
		margin-bottom: 2 * @padding-m;
	}
}
// On small mobile sizes
@media screen and (max-width: @breakpoint-mobile-small - 1) {
	.territorybox-flex {
		gap: @padding-s;
		margin-left: @padding-m;
	}

	.territorybox {
		width: 260px;
		height: 150px;
		padding: @padding-s;
		margin-bottom: @padding-s;
	}

	.territorybox.large {
		width: 260px;
		height: 240px;
		margin-bottom: @padding-s;
	}
	.territorybox-tag {
		font-size: @wp-fs-mobile-h3;
	}
	.territorybox.large .territorybox-tag {
		font-size: @wp-fs-mobile-h0;
	}

	.territorybox-tag,
	.territorybox-name {
		text-align: center;
	}

	.territorybox-link {
		display: none;
	}
	.territorybox-name {
		font-size: @wp-fs-mobile-xs;
		margin-bottom: 0;
	}
	.territorybox.large .territorybox-name {
		font-size: @wp-fs-mobile-base;
		margin-bottom: 0;
	}
}
