.top-button-wrapper {
	position: fixed;
	bottom: @padding-m;
	right: @padding-m;
}

.top-button {
	position: relative;
	transition: all .3s ease;
	opacity: 0;
	visibility: hidden;
}

.top-button.fadeIn {
	opacity: 1;
	visibility: visible;
}
