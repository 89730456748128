// this allows using a truncate inside a flexbox with vertical-align:baseline -
// inline-block alone sets the baseline to the bottom of the component
// @see https://kizu.dev/flex-baseline/
.Truncate {
	display: inline-flex;
	max-width: 100%;
}

.Truncate-inner {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	// prevent cutting off descenders inside a table
	// NOTE this probably still breaks if content inside the truncate has an increased font size
	padding-bottom: 1em;
	margin-bottom: -1em;
}
