.blogsignature {
	margin-top: @padding-l;
	border-top: 1px dashed @color-brand-grey;
}

.blogsignature-image-and-signature {
	display: flex;
	align-items: center;
}

.blogsignature-image-wrapper {
	flex-shrink: 0;
}

.blogsignature-image {
	width: 100%;
}

.blogsignature-signature {
	font-family: @font-handdrawn;
	letter-spacing: @font-handdrawn-letterspacing;
	margin-left: @padding-m;
}

.blogsignature-texts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	line-height: @line-height-s;
}

.blogsignature-expert {
	margin-bottom: @padding-s;
}

.blogsignature-expert-title,
.blogsignature-blog-date {
	color: @color-brand-grey;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.blogsignature-image-and-signature {
		width: 60%;
	}
	.blogsignature-signature {
		font-size: @wp-fs-h0;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.blogsignature-image-and-signature {
		width: 50%;
	}
	.blogsignature-signature {
		font-size: @wp-fs-tablet-h0;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.blogsignature {
		margin-bottom: @padding-l;
	}
	.blogsignature-image-and-signature {
		border-bottom: 1px dashed @color-brand-grey;
		margin-bottom: @padding-l;
	}
	.blogsignature-image-wrapper {
		width: 50%;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.blogsignature {
		border-bottom: 1px dashed @color-brand-grey;
		display: flex;
	}
	.blogsignature-image-wrapper {
		width: 33%;
	}
}

// workaround for signature on mobile screens
@media screen and (min-width: 650px) and (max-width: @breakpoint-mobile-max) {
	.blogsignature-signature {
		font-size: @wp-fs-h0;
	}

}
@media screen and (min-width: 550px) and (max-width: 649px) {
	.blogsignature-signature {
		font-size: 50px;
	}

}
@media screen and (min-width: 450px) and (max-width: 549px) {
	.blogsignature-signature {
		font-size: 40px;
	}

}
@media screen and (min-width: 400px) and (max-width: 449px) {
	.blogsignature-signature {
		font-size: 30px;
	}

}
@media screen and (max-width: 399px) {
	.blogsignature-signature {
		font-size: @wp-fs-mobile-h0;
	}

}
