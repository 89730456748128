.benefititem-image-headline-container {
	margin-top: @padding-m;
}

.benefititem-text {
	line-height: @line-height-m;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.benefititem-image-wrapper {
		height: 104px;
		width: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.benefititem-image {
		width: 70px;
		height: 70px;
	}
	.benefititem-texts {
		margin: 0 @padding-l;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.benefititem-image-wrapper {
		height: 122px;
		width: 122px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.benefititem-image {
		width: 60px;
		height: 60px;
	}
	.benefititem-texts {
		margin: 0 @padding-m;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.benefititem {
		background-color: #f7f8f6;
		border: 1px solid @color-body;
		border-radius: @border-radius;
		margin-top: @padding-m;
		margin-right: @padding-m;
		padding-bottom: @padding-m;
		flex-shrink: 0;
		width: 250px;
	}
	.benefititem-image-headline-container {
		display: flex;
	}
	.benefititem-image-wrapper {
		padding: 5px 20px;
	}
	.benefititem-image {
		width: 50px;
		height: 50px;
	}
	.benefititem-texts {
		margin: 0 @padding-i;
	}
	.benefititem-headline-tablet-and-Desktop {
		display: none;
	}
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.benefititem {
		width: 50%;
		padding: 10px 0;
		display: flex;
	}
	.benefititem-image-wrapper {
		background-color: #f7f8f6;
		border: 1px solid @color-body;
		border-radius: @border-radius;
	}
	.benefititem-headline-mobile {
		display: none;
	}
}
