.Modal::backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	background: @color-modal-backdrop-bg;
}

.Modal {
	position: fixed;
	min-width: calc(@modal-content-min-width + 2 * @padding-xxl);
	max-width: calc(100vw - @padding-xxl);
	max-height: 95vh;
	margin: auto;
	overflow-y: auto;
	background: @color-white;
	padding: @padding-xxl;
	border: none;
	border-radius: @border-radius;
	box-shadow: @box-shadow;
}

.Modal-header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	gap: @padding-s;
}

.Modal-close {
	font-size: @fs-3;
}

.Modal-title {
	margin: 0;
	font-size: @fs-2;
}

.Modal-content {
	margin: @padding-l 0 @padding-xxl;
}

.Modal-buttons {
	display: flex;
	justify-content: space-between;
	gap: @padding-xxl;
}

.Modal-buttons .Decorated {
	flex-grow: 1;
	flex-basis: 0;
}

.Modal-buttons .Decor {
	width: 100%;
	height: 100%;
}

@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.Modal {
		min-width: 100%;
		max-width: 100%;;
		max-height: 100%;
		padding: @padding-l;
	}
	.Modal-content {
		margin: @padding-l 0 @padding-l;
	}
	.Modal-buttons {
		gap: @padding-l;
	}
}

@media screen and (max-width: @breakpoint-mobile-max) {
	.Modal {
		min-width: 100%;
		max-width: 100%;;
		max-height: 100%;
		padding: @padding-m;
	}
	.Modal-content {
		margin: @padding-m 0 @padding-m;
	}
	.Modal-buttons {
		gap: @padding-m;
	}
}
