.footer {
	color: @color-white;
	background: @color-brand-grey;
}

.footer-navigation a:hover {
	text-decoration: underline;
}

.footer-brandicon {
	width: 40px;
	height: 40px;
	margin-top: 10px;
}

.footer-socialmedia ul {
	display: flex;
	justify-content: center;
	gap: @padding-i;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.footer-content {
		width: @breakpoint-desktop-min;
		margin: 0 auto;
		padding: 3 * @padding-l 0;
	}

	.footer-content-twocol {
		display: flex;
		flex-flow: row nowrap;
		margin-bottom: @padding-l 0;
	}

	.footer-title {
		font-size: @wp-fs-h4;
		margin-bottom: @padding-l;
	}

	.footer-trust {
		width: 33%;
	}

	.footer-partner {
		width: 66%;
		flex-grow: 1;
	}

	.footer-trust:empty {
		width: 0;
	}

	.footer-navigation,
	.footer-socialmedia {
		width: 100%;
		text-align: center;
		margin: @padding-l 0;

	}

	.footer-navigation li {
		display: inline;
	}

	.footer-navigation li:not(:first-child) {
		margin-left: @padding-i;
	}
}

// TODO wp layout mobile footer
// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.footer {
		padding: @padding-l 0 3 * @padding-l 0;
	}

	.footer-title,
	.footer-partner {
		display: none;
	}

	.footer-content {
		display: flex;
		justify-content: flex-end;
		position: relative;
	}

	// Height is the same as three one-line items in the navigation menu
	.footer-trust {
		position: absolute;
		left: @padding-m;
		top: @footer-mobile-navi-offset;
	}
	.footer-trust img {
		aspect-ratio: 250/179;
	}

	.footer-navigation {
		position: relative;
		padding-top: @footer-mobile-navi-offset;
		padding-left: 130px;
		margin-left: @padding-m;
	}
	.footer-navigation:before {
		content: '';
		background-image: url('/img/wp/klammer-white.svg');
		background-size: cover;
		background-repeat: no-repeat;
		width: 211px;
		height: 112px;
		position: absolute;
		top: 0;
		left: 0;
	}
	.footer-navigation li:not(:last-child) {
		margin-bottom: @padding-l;
	}

	.footer-socialmedia {
		margin-top: @padding-l;
	}

	.footer-socialmedia ul {
		justify-content: flex-start;
	}
}
