.hintbox-content > *:not(:last-child) {
	margin-bottom: @padding-m;
}

.hintbox > .headline,
.hintitem:first-child .hintitem-headline {
	margin-top: 0;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.hintbox > .headline {
		margin-bottom: 2 * @padding-m;
	}
	.hintbox-content {
		columns: 2;
	}
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.hintbox-inner {
		padding: @padding-l 0;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.hintbox-content {
		flex-direction: column;
	}
}
