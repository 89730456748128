.regionitem {
	color: @color-white;
}

.regionitem-title-wrapper {
	line-height: @line-height-xs;
}

.regionitem-discovernow {
	visibility: hidden;
	opacity: 0;
	text-align: right;
}

// Size of the old region images is 468x250, so they should probably be replaced
// some with a ratio of 3:5 (for example 10000x600)
// TODO css shadow mirror effect of image on background
.regionitem-image {
	border-radius: @border-radius;
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: @box-drop-shadow;
}

.regionitem:hover .regionitem-image {
	box-shadow: none;
}

.regionitem .hl-3 {
	margin-bottom: @padding-s;
}

// Only Tablets and Desktop
@media screen and (min-width: @breakpoint-tablet-min) {
	.regionitem {
		display: flex;
		width: 50%;
		padding: @padding-m;
		transition: all .2s ease-in;
	}

	.regionitem-image {
		width: 180px;
		height: 108px;
		flex-shrink: 0;
	}
	.regionitem:hover {
		color: @color-body;
		background: @color-white;
		border-radius: @border-radius;
		box-shadow: @box-drop-shadow;
		transition: all .2s ease-in;
	}

	.regionitem:hover .regionitem-discovernow {
		visibility: visible;
		opacity: 1;
	}

}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.regionitem-texts {
		flex-grow: 1;
		margin-left: @padding-m;
		display: flex;
		flex-flow: column;
		justify-content: space-between;
	}
}

// Only Tablets
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.regionitem {
		flex-direction: column;
	}
}

// Only Mobile
@media screen and (max-width: @breakpoint-mobile-max) {
	.regionitem {
		width: 100%;
		flex-direction: column;
		margin-bottom: @padding-m;
	}
	.regionitem-image {
		width: 100%;
		padding-bottom: 60%;
		margin-bottom: @padding-i;
	}
}
