.routeuplinktexts-headline.routeuplinktexts-headline {
	text-transform: uppercase;
	display: flex;
	flex-direction: column;
	margin-top: 0;
}

.routeuplinktexts-text-content {
	line-height: @line-height;
}

// Only Desktop
@media screen and (min-width: @breakpoint-desktop-min) {
	.routeuplinktexts-headline,
	.routeuplinktexts-text-content {
		margin-bottom: @padding-l;
	}
}

// Only Tablets and Mobile
@media screen and (max-width: @breakpoint-tablet-max) {
	.routeuplinktexts-headline,
	.routeuplinktexts-text-content {
		margin-bottom: @padding-m;
	}
}
